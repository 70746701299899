import "./Modal.scss";
import Modal from "react-modal";
import { Button, ButtonType } from "../Button/Button";
import { useTranslation } from "react-i18next";
import { IconCircleExclamation } from "../Icons/icon_screen_size";
import { useEffect, useState } from "react";
import { MessagingStage, messageService } from "@/utils/messaging.service";
import { monitor } from "@/utils/monitoring.service";

export interface iScreenSizeTooSmallModal {
  className?: string;
}

export const ScreenSizeTooSmallModal = ({ className = "" }: iScreenSizeTooSmallModal) => {
  const { t } = useTranslation();
  const MINIMAL_WIDTH = 290;
  const MINIMAL_HEIGHT = 480;

  // State to track whether the modal should be displayed
  const [showModal, setShowModal] = useState(false);

  // Effect to update modal display based on screen size
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Set showModal based on minimal screen width and height
      setShowModal(screenWidth <= MINIMAL_WIDTH || screenHeight <= MINIMAL_HEIGHT);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onDismiss = () => {
    monitor.trackScreenTooSmall(window.innerWidth, window.innerHeight, MINIMAL_WIDTH, MINIMAL_HEIGHT);
    messageService.send({
      stage: MessagingStage.UserFinished,
      data: {},
    });
    messageService.send({
      stage: MessagingStage.Dismiss,
      data: {},
    });
  };

  return (
    <Modal isOpen={showModal} contentLabel="Error!" className={`modal w-[calc(100%-40px)] sm:max-w-[400px] sm:min-w-[400px] ${className}`}>
      <div className="flex flex-col rounded-[20px] justify-center items-center">
        <div className="text-center my-5">
          <IconCircleExclamation />
        </div>

        <div className="text-support-500 dark:text-support-100 text-2xl font-semibold my-4 text-center">{t("screen_size_too_small")}</div>

        <div className="text-center text-support-500 dark:text-support-100  text-md">{t("screen_size_too_small_desc")}</div>

        <Button
          text={t("close_current_measurement")}
          type={ButtonType.Outline}
          containerClass="uppercase mt-10"
          onClick={() => onDismiss()}
        />
      </div>
    </Modal>
  );
};
