import cx from "classnames";

export interface iVideoMarker {
  color?: string;
}

export const VideoMarkerTop = ({ color = "#62E38E" }: iVideoMarker) => {
  return (
    <svg
      width="337"
      height="26"
      viewBox="0 0 337 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 3H11C6.58172 3 3 6.58172 3 11V23"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M314 3H326C330.418 3 334 6.58172 334 11V23"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const VideoMarkerBottom = ({ color = "#62E38E" }: iVideoMarker) => {
  return (
    <svg
      width="337"
      height="26"
      viewBox="0 0 337 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 23H11C6.58172 23 3 19.4183 3 15V3"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M314 23H326C330.418 23 334 19.4183 334 15V3"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const VideoMarker = ({ color = "#62E38E" }: iVideoMarker) => {
  return (
    <svg
      width="331"
      height="527"
      viewBox="0 0 323 478"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx("h-full w-full pt-[10px]", "pb-[8vh]")}
    >
      <path
        d="M23 3H11C6.58172 3 3 6.58172 3 11V23"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M299 3H311C315.418 3 319 6.58172 319 11V23"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M23 475H11C6.58172 475 3 471.418 3 467V455"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M299 475H311C315.418 475 319 471.418 319 467V455"
        stroke={color}
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};
