"use client";

import "./Checkbox.scss";
import React from "react";
import Typography from "../Typography/Typography";
import cx from "classnames";

interface ICheckbox {
  value: boolean | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  label: any;
  name: string;
  errors?: string | undefined;
  touched?: boolean | undefined;
  className?: string;
}

export default function Checkbox({ value, onChange, label, name, errors, touched, className }: ICheckbox) {
  const hasErrors = Boolean(errors && touched && errors);
  return (
    <div className={className}>
      <label className="custom-label inline-flex cursor-pointer">
        <div
          className={cx("filter-checkbox w-[23px] h-[23px] p-1 flex justify-center items-center mr-2 rounded-md border shadow-md", {
            "bg-brand-primary border-brand-primary dark:bg-brand-secondary": value,
          })}
        >
          <input type="checkbox" className="hidden" name={name} onChange={onChange} checked={value} />
          <svg className={cx("fill-brand-tertiary")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>
        </div>
        {label}
      </label>
      {Boolean(hasErrors) && <Typography.Text className="text-red-600">{errors && touched && errors}</Typography.Text>}
    </div>
  );
}
