import React, { useState, useEffect } from "react";

export type WindowContextType = {
  windowIsActive: boolean;
};

export const WindowContext = React.createContext<WindowContextType>({
  windowIsActive: true,
});

export const WindowContextProvider = (props: any) => {
  const [windowIsActive, setWindowIsActive] = useState<boolean>(true);

  function onVisibilityChanged() {
    return document.hidden ? setWindowIsActive(false) : setWindowIsActive(true);
  }

  useEffect(() => {
    document.addEventListener("visibilitychange", () => onVisibilityChanged());

    return () => {
      document.removeEventListener("visibilitychange", () => onVisibilityChanged());
    };
  }, []);

  return (
    <WindowContext.Provider value={{ windowIsActive }}>
      {props.children}
    </WindowContext.Provider>
  );
};
