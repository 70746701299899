export type iIconCircleNext = {
  background?: string;
  color?: string;
};

export const IconCircleNext = ({ background = "#3A79FF", color = "#FFFFFF" }: iIconCircleNext) => {
  return (
    <svg
      width="24"
      height="24"
	  viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24"  rx="18" fill={background} />
      <path
		d="M17.559 10.9376C18.1449 11.5233 18.1449 12.4745 17.559 13.0603L8.55898 22.0573C7.97305 22.643 7.02148 22.643 6.43555 22.0573C5.84961 21.4715 5.84961 20.5203 6.43555 19.9345L14.3762 11.9966L6.44023 4.05861C5.8543 3.47287 5.8543 2.52163 6.44023 1.93589C7.02617 1.35015 7.97773 1.35015 8.56367 1.93589L17.5637 10.9329L17.559 10.9376Z"
        fill={color}
      />
    </svg>
  );
};
