import { useTheme } from "@/context/theme";
import { iIconOnboarding } from "./type";

export const IconOnboarding3 = ({ ...props }: iIconOnboarding) => {
  const theme = useTheme();

  return (
    <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M58 174.004L151 174.004" stroke={theme.value.functional_tertiary_500} strokeWidth="5" strokeLinecap="round" />
      <path
        d="M120 39.8289C126.418 39.8289 131.625 44.9739 131.625 51.3154V97.2618H135.5V51.3154C135.5 42.868 128.549 36 120 36H89C80.4508 36 73.5 42.868 73.5 51.3154V97.2618H77.375V51.3154C77.375 44.9739 82.582 39.8289 89 39.8289H120ZM61.875 76.2031C61.875 75.1501 61.0031 74.2886 59.9375 74.2886C58.8719 74.2886 58 75.1501 58 76.2031V95.3474C58 96.4003 58.8719 97.2618 59.9375 97.2618C61.0031 97.2618 61.875 96.4003 61.875 95.3474V76.2031ZM151 76.2031C151 75.1501 150.128 74.2886 149.062 74.2886C147.997 74.2886 147.125 75.1501 147.125 76.2031V95.3474C147.125 96.4003 147.997 97.2618 149.062 97.2618C150.128 97.2618 151 96.4003 151 95.3474V76.2031ZM69.625 108.748H139.375C140.852 108.748 142.184 109.562 142.838 110.854L146.713 118.512C147.319 119.709 147.246 121.12 146.544 122.245C145.841 123.37 144.582 124.064 143.25 124.064H104.5H65.75C64.418 124.064 63.1586 123.37 62.4563 122.245C61.7539 121.12 61.6813 119.709 62.2867 118.512L66.1617 110.854C66.8156 109.562 68.1719 108.748 69.625 108.748ZM106.438 127.893H143.25C145.938 127.893 148.433 126.529 149.838 124.255C151.242 121.982 151.388 119.182 150.177 116.813L146.302 109.155C144.994 106.547 142.305 104.92 139.375 104.92H69.625C66.6945 104.92 64.0063 106.547 62.6984 109.155L58.8234 116.813C57.6125 119.182 57.7578 122.006 59.1625 124.255C60.5672 126.505 63.0617 127.893 65.75 127.893H102.562V143.208H94.8125C87.3289 143.208 81.25 149.215 81.25 156.609C81.25 157.662 82.1219 158.524 83.1875 158.524C84.2531 158.524 85.125 157.662 85.125 156.609C85.125 151.321 89.4602 147.037 94.8125 147.037H102.562V156.609C102.562 157.662 103.434 158.524 104.5 158.524C105.566 158.524 106.438 157.662 106.438 156.609V147.037H114.188C119.54 147.037 123.875 151.321 123.875 156.609C123.875 157.662 124.747 158.524 125.812 158.524C126.878 158.524 127.75 157.662 127.75 156.609C127.75 149.215 121.671 143.208 114.188 143.208H106.438V127.893Z"
        fill={theme.value.support_500}
      />
    </svg>
  );
};
