import { useTheme } from "@/context/theme";
import { SVGProps } from "react";

export const IconCircleExclamation = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      width="88"
      height="60"
      viewBox="0 0 88 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.8889 2.8055H6.11111C5.09859 2.8055 4.27778 3.62631 4.27778 4.63884V55.3611H83.7222V4.63884C83.7222 3.62631 82.9014 2.8055 81.8889 2.8055ZM2.44444 4.63884V55.3611H0V56.5833C0 57.9333 1.09441 59.0277 2.44444 59.0277H6.11111H81.8889H85.5556C86.9056 59.0277 88 57.9333 88 56.5833V55.3611H85.5556V4.63884C85.5556 2.61379 83.9139 0.972168 81.8889 0.972168H6.11111C4.08607 0.972168 2.44444 2.61379 2.44444 4.63884ZM38.5 56.5833C38.1625 56.5833 37.8889 56.8569 37.8889 57.1944C37.8889 57.5319 38.1625 57.8055 38.5 57.8055H49.5C49.8375 57.8055 50.1111 57.5319 50.1111 57.1944C50.1111 56.8569 49.8375 56.5833 49.5 56.5833H38.5Z"
        fill={theme.value.support_500}
      />
      <path
        d="M26.8889 47.1108L33.705 45.2845L28.7153 40.2948L26.8889 47.1108ZM61.1111 12.8886L54.2951 14.715L59.2848 19.7047L61.1111 12.8886ZM31.2101 43.6539L57.6542 17.2098L56.7899 16.3456L30.3459 42.7896L31.2101 43.6539Z"
        fill={theme.value.support_500}
      />
      <path
        d="M26.9969 12.9966L33.813 14.8229L28.8233 19.8126L26.9969 12.9966ZM61.2192 47.2188L54.4031 45.3924L59.3928 40.4027L61.2192 47.2188ZM31.3182 16.4535L57.7622 42.8976L56.898 43.7618L30.4539 17.3178L31.3182 16.4535Z"
        fill={theme.value.support_500}
      />
    </svg>
  );
};
