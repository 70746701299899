import { useTheme } from "@/context/theme";
import { iIconOnboarding } from "./type";

export const IconOnboarding4 = ({ ...props }: iIconOnboarding) => {
  const theme = useTheme();

  return (
    <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_1258_1738)">
        <path
          d="M147.5 84C147.5 92.475 144.711 100.325 139.93 106.8C138.734 108.425 137.406 110.125 136.078 111.85C132.758 116.15 129.198 120.725 126.436 125.45C124.47 128.825 123.355 132.4 122.717 136H127.047C127.631 133 128.614 130.075 130.181 127.375C132.811 122.875 136.078 118.65 139.345 114.425C140.727 112.65 142.108 110.875 143.436 109.075C148.695 101.95 151.777 93.325 151.777 84C151.75 59.7 130.819 40 105 40C79.1813 40 58.25 59.7 58.25 84C58.25 93.325 61.3312 101.975 66.5906 109.075C67.9187 110.875 69.3 112.65 70.6812 114.425C73.9484 118.625 77.2156 122.85 79.8453 127.375C81.4125 130.075 82.3953 133 82.9797 136H87.2828C86.6453 132.4 85.5297 128.825 83.5641 125.45C80.8016 120.7 77.2688 116.125 73.9219 111.85C72.5938 110.125 71.2656 108.425 70.0703 106.8C65.2891 100.325 62.5 92.475 62.5 84C62.5 61.9 81.5188 44 105 44C128.481 44 147.5 61.9 147.5 84ZM79.5 82C79.5 69.85 89.9656 60 102.875 60C104.044 60 105 59.1 105 58C105 56.9 104.044 56 102.875 56C87.6281 56 75.25 67.65 75.25 82C75.25 83.1 76.2063 84 77.375 84C78.5438 84 79.5 83.1 79.5 82ZM105 164C95.6234 164 88 156.825 88 148H122C122 156.825 114.377 164 105 164ZM83.75 146.675V148C83.75 159.05 93.2594 168 105 168C116.741 168 126.25 159.05 126.25 148V146.675C126.25 145.2 124.975 144 123.408 144H86.5922C85.025 144 83.75 145.2 83.75 146.675Z"
          fill={theme.value.support_500}
        />
        <path
          d="M28.7922 40.9493C29.4031 40.0243 30.7047 39.6993 31.7141 40.2743L52.9641 52.2744C53.9469 52.8493 54.2922 54.0993 53.6813 55.0244C53.0703 55.9494 51.7688 56.2744 50.7594 55.6994L29.5094 43.6993C28.5266 43.1243 28.1812 41.8743 28.7922 40.9493Z"
          fill={theme.value.functional_tertiary_500}
        />
        <path
          d="M181.181 40.9493C181.792 41.8993 181.473 43.1243 180.464 43.6993L159.214 55.6994C158.205 56.2744 156.903 55.9744 156.292 55.0244C155.681 54.0744 156 52.8493 157.009 52.2744L178.259 40.2743C179.269 39.6993 180.57 39.9993 181.181 40.9493Z"
          fill={theme.value.functional_tertiary_500}
        />
        <path
          d="M20 81.9993C20 80.8993 20.9562 79.9993 22.125 79.9993H43.375C44.5438 79.9993 45.5 80.8993 45.5 81.9993C45.5 83.0993 44.5438 83.9993 43.375 83.9993H22.125C20.9562 83.9993 20 83.0993 20 81.9993Z"
          fill={theme.value.functional_tertiary_500}
        />
        <path
          d="M164.5 81.9993C164.5 80.8993 165.456 79.9993 166.625 79.9993H187.875C189.044 79.9993 190 80.8993 190 81.9993C190 83.0993 189.044 83.9993 187.875 83.9993H166.625C165.456 83.9993 164.5 83.0993 164.5 81.9993Z"
          fill={theme.value.functional_tertiary_500}
        />
        <path
          d="M53.6813 104.974C54.2922 105.924 53.9734 107.149 52.9641 107.724L31.7141 119.724C30.7047 120.299 29.4031 119.999 28.7922 119.049C28.1812 118.099 28.5 116.874 29.5094 116.299L50.7594 104.299C51.7688 103.724 53.0703 104.024 53.6813 104.974Z"
          fill={theme.value.functional_tertiary_500}
        />
        <path
          d="M156.292 104.974C156.903 104.049 158.205 103.724 159.214 104.299L180.464 116.299C181.447 116.874 181.792 118.124 181.181 119.049C180.57 119.974 179.269 120.299 178.259 119.724L157.009 107.724C156.027 107.149 155.681 105.899 156.292 104.974Z"
          fill={theme.value.functional_tertiary_500}
        />
      </g>
    </svg>
  );
};
