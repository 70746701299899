import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { EN } from "./";

const availableLang = ["en", "nl", "fr"];
const navigatorLang = (navigator?.language ?? "en").slice(0, 2);

export const defaultLang = availableLang.includes(navigatorLang) ? navigatorLang : "en";

const languageDetector = new LanguageDetector(null, {
  order: ["cookie", "localStorage", "sessionStorage", "navigator", "htmlTag"],
  caches: ["cookie"],
});

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources: {
      en: {
        translation: EN,
      },
    },
    fallbackLng: defaultLang,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
