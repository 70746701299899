import { useTheme } from "@/context/theme";

export const IconInfo = ({ ...props }: { fill?: string; className?: string }) => {
  const theme = useTheme();

  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.5 0.875C9.12445 0.875 10.6824 1.52031 11.831 2.66897C12.9797 3.81763 13.625 5.37555 13.625 7C13.625 8.62445 12.9797 10.1824 11.831 11.331C10.6824 12.4797 9.12445 13.125 7.5 13.125C5.87555 13.125 4.31763 12.4797 3.16897 11.331C2.02031 10.1824 1.375 8.62445 1.375 7C1.375 5.37555 2.02031 3.81763 3.16897 2.66897C4.31763 1.52031 5.87555 0.875 7.5 0.875ZM7.5 14C9.35651 14 11.137 13.2625 12.4497 11.9497C13.7625 10.637 14.5 8.85651 14.5 7C14.5 5.14348 13.7625 3.36301 12.4497 2.05025C11.137 0.737498 9.35651 0 7.5 0C5.64348 0 3.86301 0.737498 2.55025 2.05025C1.2375 3.36301 0.5 5.14348 0.5 7C0.5 8.85651 1.2375 10.637 2.55025 11.9497C3.86301 13.2625 5.64348 14 7.5 14ZM6.1875 9.625C5.94687 9.625 5.75 9.82187 5.75 10.0625C5.75 10.3031 5.94687 10.5 6.1875 10.5H8.8125C9.05312 10.5 9.25 10.3031 9.25 10.0625C9.25 9.82187 9.05312 9.625 8.8125 9.625H7.9375V6.5625C7.9375 6.32187 7.74062 6.125 7.5 6.125H6.40625C6.16562 6.125 5.96875 6.32187 5.96875 6.5625C5.96875 6.80312 6.16562 7 6.40625 7H7.0625V9.625H6.1875ZM7.5 5.03125C7.67405 5.03125 7.84097 4.96211 7.96404 4.83904C8.08711 4.71597 8.15625 4.54905 8.15625 4.375C8.15625 4.20095 8.08711 4.03403 7.96404 3.91096C7.84097 3.78789 7.67405 3.71875 7.5 3.71875C7.32595 3.71875 7.15903 3.78789 7.03596 3.91096C6.91289 4.03403 6.84375 4.20095 6.84375 4.375C6.84375 4.54905 6.91289 4.71597 7.03596 4.83904C7.15903 4.96211 7.32595 5.03125 7.5 5.03125Z"
        fill={theme.value.support_500}
      />
    </svg>
  );
};
