import { useTheme } from "@/context/theme";
import { iIconOnboarding } from "./type";

import cx from "classnames";

export const IconOnboardingMobile1 = ({ className, ...props }: iIconOnboarding) => {
  const theme = useTheme();

  return (
    <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" className={cx(className)} {...props}>
      <path
        d="M78 37.5C70.5469 37.5 64.5 43.5469 64.5 51V159C64.5 166.453 70.5469 172.5 78 172.5H132C139.453 172.5 145.5 166.453 145.5 159V51C145.5 43.5469 139.453 37.5 132 37.5H78ZM60 51C60 41.0719 68.0719 33 78 33H132C141.928 33 150 41.0719 150 51V159C150 168.928 141.928 177 132 177H78C68.0719 177 60 168.928 60 159V51Z"
        fill={theme.value.support_500}
      />
      <path
        d="M81.001 131H74.001C72.8964 131 72.001 130.105 72.001 129V122"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M129.001 131H136.001C137.106 131 138.001 130.105 138.001 129V122"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M81.001 49H74.001C72.8964 49 72.001 49.8954 72.001 51V58"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M129.001 49H136.001C137.106 49 138.001 49.8954 138.001 51V58"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M104.967 99.5442C116.647 99.5442 126.116 90.0199 126.116 78.2721C126.116 66.5242 116.647 57 104.967 57C93.2874 57 83.8184 66.5242 83.8184 78.2721C83.8184 90.0199 93.2874 99.5442 104.967 99.5442ZM104.967 59.659C115.188 59.659 123.472 67.9917 123.472 78.2721C123.472 88.5525 115.188 96.8852 104.967 96.8852C94.7463 96.8852 86.4619 88.5525 86.4619 78.2721C86.4619 67.9917 94.7463 59.659 104.967 59.659Z"
        fill={theme.value.support_500}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.9651 116.775L82.9339 116.795C82.362 117.17 81.5894 117.018 81.2082 116.455C80.8269 115.893 80.9814 115.133 81.5533 114.757C83.8376 113.259 90.474 108.954 104.999 108.954C119.524 108.954 126.16 113.259 128.445 114.757C129.017 115.133 129.171 115.893 128.79 116.455C128.409 117.018 127.636 117.17 127.064 116.795L127.033 116.775C125.018 115.452 118.846 111.403 104.999 111.403C91.1521 111.403 84.9805 115.452 82.9651 116.775Z"
        fill={theme.value.support_500}
      />
      <g>
        <rect x="72" y="145.5" width="66" height="19.5" rx="4" fill={theme.value.support_300} />
      </g>
      <ellipse cx="105.001" cy="155.251" rx="6" ry="6" fill="white" />
      <ellipse cx="105.001" cy="155.251" rx="7.5" ry="7.5" stroke="white" />
    </svg>
  );
};
