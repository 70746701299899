import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import cx from "classnames";

import "./Result.scss";
import { useResult } from "./Result.hook";
import { ResultBiomarkerCard } from "@/components/ResultBiomarkerCard/ResultBiomarkerCard";
import { Button, ButtonType } from "@/components/Button/Button";
import { Header } from "@/components/Header/Header";
import { BaseLayout } from "@/components/Layout/BaseLayout";
import { ResultFeedback } from "./ResultFeedback/ResultFeedback";
import { useEffect, useState } from "react";
import { AppModal } from "@/components/Modal/Modal";
import { useAppSelector } from "@/hooks";
import { shallowEqual } from "react-redux";
import { MessagingStage, messageService } from "@/utils/messaging.service";
import { IconChevronLeft } from "@/components/Icons/Icon_chevron_left";
import { useTheme } from "@/context/theme";

export const ResultPage = () => {
  const { uuid } = useParams();
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  const { buckets, activeBucket, selectBucket, activeFeedback, alert, resultError } = useResult(uuid, state?.result);
  const { t } = useTranslation();
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);
  const [showDetail, setShowDetail] = useState("");
  const [trendsPageEnabled, setTrendsPageEnabled] = useState(false);
  const theme = useTheme();

  const __renderButtons = () => {
    if (buckets) {
      return (buckets?.buckets ?? []).map((bucket) => {
        return (
          <Button
            key={bucket.name}
            text={t(bucket.name)}
            containerClass={cx("mx-2 uppercase whitespace-nowrap h-[42px]", {
              "shadow-md": activeBucket && activeBucket.name === bucket.name,
            })}
            type={activeBucket && activeBucket.name === bucket.name ? ButtonType.Accent : undefined}
            onClick={() => {
              selectBucket(bucket);
              setShowDetail("");
            }}
            // onClick={}
          />
        );
      });
    }

    return (
      <div className="flex">
        {Array.from(Array(4).keys()).map((m: number) => (
          <Skeleton
            key={m}
            count={1}
            width={140}
            height={40}
            inline
            className="mx-2"
            baseColor={theme.value.dark_mode ? "#1A1A1A" : "#fff"}
            highlightColor="#F7F9FD"
          />
        ))}
      </div>
    );
  };

  const __renderCards = () => {
    if (activeBucket) {
      return activeBucket.biomarkers.map((biomarker) => {
        return (
          <ResultBiomarkerCard
            key={[activeBucket.name, biomarker.name].join("_")}
            biomarker={biomarker}
            setShowDetail={setShowDetail}
            showDetail={showDetail}
          />
        );
      });
    }

    return (
      <>
        <ResultBiomarkerCard setShowDetail={setShowDetail} showDetail={showDetail} />
        <ResultBiomarkerCard setShowDetail={setShowDetail} showDetail={showDetail} />
      </>
    );
  };

  useEffect(() => {
    if (!setting.pluginSettings?.feature_flags?.trends_page) return;

    const enough = buckets?.buckets.some((b) => b.history.length > 1);
    if (enough) {
      setTrendsPageEnabled(true);
    }
  }, [buckets]);

  return (
    <BaseLayout containerClass="result-page relative" withPadding={false}>
      {alert && (
        <div className="absolute left-1/2 transform -translate-x-1/2 top-[24px] z-10">
          <div
            className="text-lg font-semibold text-white rounded-[12px] px-[24px] py-[12px]"
            style={{
              backgroundColor: "rgba(var(--rgb-brand-secondary), 0.64)",
            }}
          >
            {alert}
          </div>
        </div>
      )}
      <div className="px-4 md:px-[25px]">
        <Header />
      </div>
      <div className="flex flex-col items-center mx-4 sm:mx-0 flex-grow">
        <div
          className={cx(
            "flex flex-row items-center p-4 overflow-scroll max-w-[100%]",
            // responsive
            "sm:py-0 sm:my-5 sm:overflow-visible sm:max-w-[unset] sm:justify-center",
          )}
        >
          {__renderButtons()}
        </div>

        <div className="flex w-full">
          <div className="flex flex-col w-full pt-6 sm:mx-6 sm:mt-6">
            <p className="text-md result-heading text-center mb-6">
              {activeBucket ? (
                t(`${activeBucket.name}_info`, "Bucket info")
              ) : (
                <Skeleton width="200px" height="24px" baseColor={theme.value.dark_mode ? "#1A1A1A" : "#F7F9FD"} />
              )}
            </p>

            <div
              className={cx(
                "self-center w-full gap-[20px] justify-center justify-items-center lg:w-full",
                // responsive
                "lg:gap-[32px] lg:flex lg:flex-row",
                showDetail ? "flex flex-row sm:w-full" : "grid grid-cols-2 sm:w-[480px]",
              )}
            >
              {__renderCards()}
            </div>

            <ResultFeedback feedback={activeFeedback} />
          </div>
        </div>

        <div className="mt-8 mb-8 flex flex-col sm:flex-row gap-4">
          {trendsPageEnabled && (
            <Button
              textStyles={{ textTransform: "uppercase" }}
              type={ButtonType.Secondary}
              text={t("measurement_history", "Measurement History")}
              onClick={() =>
                navigate(`/measurement/history/${uuid}`, {
                  state: { buckets: buckets, from: location.pathname },
                })
              }
              className="h-[50px] px-[32px] flex-grow"
              suffix={<IconChevronLeft className="rotate-180 h-[10px]" />}
            />
          )}

          {setting.pluginSettings?.embedded == false && (
            <Button
              textStyles={{ textTransform: "uppercase" }}
              type={ButtonType.Accent}
              text={t("start_new_measurement", "Start new measurement")}
              onClick={() => navigate("/measurement/do")}
              className="h-[50px] px-[32px] flex-grow"
              suffix={<IconChevronLeft className="rotate-180 h-[10px] fill-current" />}
            />
          )}
          {setting.pluginSettings?.embedded_app && (
            <Button
              textStyles={{ textTransform: "uppercase" }}
              type={ButtonType.Accent}
              text={t("close_current_measurement", "Continue")}
              onClick={() => messageService.send({ stage: MessagingStage.Dismiss, data: {} })}
              className="h-[50px] px-[32px] flex-grow"
              suffix={<IconChevronLeft className="rotate-180 h-[10px] fill-current" />}
            />
          )}
        </div>
      </div>

      <div className="flex text-support-500 items-end px-6 mb-4 pb-4 bt-8 text-center md:text-left">
        <span className="text-[8px]">{t("disclaimer_results")}</span>
      </div>

      <AppModal
        isOpen={resultError?.isOpen}
        title={resultError?.title}
        text={resultError?.text}
        dismissText={resultError?.dismissText}
        onDismiss={resultError?.onDismiss ? () => resultError?.onDismiss?.() : undefined}
        action={resultError?.action}
      />
    </BaseLayout>
  );
};
