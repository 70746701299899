import { ReactNode } from "react";
import cx from "classnames";

import "./ButtonArc.scss";
import { twMerge } from "tailwind-merge";
import { ReactComponent as IcRing } from "@/images/ic-ring.svg";
import { useTheme } from "@/context/theme";
import { toInteger } from "lodash";

export interface iButtonArc {
  content?: ReactNode | string;
  progress?: number;
  progressColor?: string;
  size?: number;
  progressSize?: number;
  noShadow?: boolean;
  track?: boolean;
  trackColor?: string;
  className?: string;
  loading?: boolean;
  darkMode?: boolean;
}

export const ButtonArc = ({
  content,
  progress = 100,
  progressColor = "#62E38E",
  size = 48,
  progressSize = 2,
  noShadow = false,
  track = false,
  trackColor = "#c2c5cc",
  className = "",
  loading = false,
  darkMode = false,
}: iButtonArc) => {
  const theme = useTheme();

  if (loading) {
    return (
      <div className={`flex m-[5px]`} style={{ width: `${size}px`, height: `${size}px` }}>
        <IcRing
          className={cx(
            "items-center w-full m-auto",
            !!(toInteger(darkMode ?? true) ^ toInteger(theme.value.dark_mode ?? false))
              ? "stroke-brand-tertiary"
              : "stroke-brand-secondary",
          )}
        />
      </div>
    );
  }

  return (
    <div className={twMerge(`relative ${className}`)}>
      {track && (
        <span
          className="track"
          style={
            {
              "--p": 100,
              "--c": trackColor,
              "--b": `${progressSize}px`,
              "--w": `${size}px`,
            } as any
          }
        />
      )}

      <div
        className={cx("button-arc__wrapper animate", { "no-shadow": noShadow })}
        style={
          {
            "--p": progress,
            "--c": progressColor,
            "--b": `${progressSize}px`,
            "--w": `${size}px`,
          } as any
        }
      >
        {content ?? null}
      </div>
    </div>
  );
};
