import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthenticationMethod, PluginSettings, QuestionItem, StreamingMetadata } from "intelliprove-streaming-sdk";

export type SettingState = {
  onboarding: boolean;
  duration?: number;
  actionToken?: string | null;
  authenticationMethod?: AuthenticationMethod;
  metadata: StreamingMetadata;
  pluginSettings?: PluginSettings;
  language?: string;
  questionnaireQuestions: QuestionItem[];
};

const initialState: SettingState = {
  onboarding: false,
  duration: 20000,
  actionToken: undefined,
  authenticationMethod: AuthenticationMethod.ActionToken,
  metadata: {
    patient: null,
    performer: null,
    userId: null,
    sessionId: null,
  },
  pluginSettings: undefined,
  questionnaireQuestions: [],
  language: "en",
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSetting: (state, action: PayloadAction<SettingState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setAuth: (state, action: PayloadAction<[string, AuthenticationMethod]>) => {
      state.actionToken = action.payload[0];
      state.authenticationMethod = action.payload[1];
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setOnboarding: (state, action: PayloadAction<boolean>) => {
      state.onboarding = action.payload;
    },
    setMetadata: (state, action: PayloadAction<StreamingMetadata>) => {
      state.metadata = action.payload;
    },
    setDuration: (state, action: PayloadAction<number>) => {
      state.duration = action.payload;
    },
    setPluginSetting: (state, action: PayloadAction<Omit<PluginSettings, "json">>) => {
      state.pluginSettings = new PluginSettings({
        ...initialState.pluginSettings,
        ...action.payload,
      });
    },
    setQuestionnaireQuestions: (state, action: PayloadAction<QuestionItem[]>) => {
      state.questionnaireQuestions = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSetting, setAuth, setLanguage, setOnboarding, setMetadata, setDuration, setPluginSetting, setQuestionnaireQuestions } =
  settingSlice.actions;

export default settingSlice.reducer;
