import { JsonObject } from "../interfaces";

export default class QualityResponse {
  score: number;
  error_code: number;
  prompt: string;
  signature: string;
  error_type: string;

  constructor(json: JsonObject) {
    this.score = parseInt(json.quality_score);
    this.error_code = parseInt(json.quality_error_code);
    this.prompt = json.prompt;
    this.signature = json.signature;
    this.error_type = json.error_type;
  }

  success(): boolean {
    return this.error_code === 0 && this.signature !== "";
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}
