export default class IntelliProveAPIError extends Error {
  status_code: number;
  body: Object | null;

  constructor(msg: string, status_code: number, body: Object | null = null) {
    super(msg);
    this.status_code = status_code;
    this.body = body;
    Object.setPrototypeOf(this, IntelliProveAPIError.prototype);
  }
}
