import { FaceLandmarker, FilesetResolver, NormalizedLandmark } from "@mediapipe/tasks-vision";
import { MESH_ANNOTATIONS_2D } from "../utils/face_landmark";

export type FaceLandmarkConfig = {
  filesetResolverBasePath?: string;
  modelAssetPath?: string;
  delegate?: "GPU" | "CPU";
  dotColor?: string;
  lineColor?: string;
  maxFps?: number;
  flipHorizontal?: boolean;
  debugMode?: boolean;
  enabled?: boolean;
};

// Define the configuration type for the FaceLandmarkService
export default class FaceLandmarkService {
  private _landmaker: FaceLandmarker;
  private _videoEl: HTMLVideoElement;
  private _config: FaceLandmarkConfig;
  private _canvas: HTMLCanvasElement;
  private _context: CanvasRenderingContext2D;
  private _frameId: number;
  private _timestamp: number = 0;
  private _offset: { x: number; y: number } = { x: 0, y: 0 };
  private _scale: number = 1;

  // Default configuration settings
  defaultConfig: FaceLandmarkConfig = {
    filesetResolverBasePath: "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm",
    modelAssetPath: "https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task",
    delegate: "GPU",
    dotColor: "#90EE90",
    lineColor: "rgba(255, 255, 255, 0.5)",
    maxFps: 60,
    flipHorizontal: false,
    enabled: true,
  };

  constructor(videoEl: HTMLVideoElement, config?: FaceLandmarkConfig) {
    this._videoEl = videoEl;
    this._config = Object.assign(this.defaultConfig, config ?? {});
  }

  async bootstrap(): Promise<void> {
    const filesetResolver = await FilesetResolver.forVisionTasks(this._config.filesetResolverBasePath);

    this._landmaker = await FaceLandmarker.createFromOptions(filesetResolver, {
      baseOptions: {
        modelAssetPath: this._config.modelAssetPath,
        delegate: this._config.delegate ?? "GPU",
      },
      outputFaceBlendshapes: true,
      runningMode: "VIDEO",
      numFaces: 1,
    });

    // Create canvas
    this._canvas = document.createElement("canvas");
    this._videoEl.parentElement.appendChild(this._canvas);
    this._canvas.style.position = "absolute";
    this._canvas.style.top = "0";
    this._context = this._canvas.getContext("2d");
  }

  get config() {
    return this._config;
  }

  changeConfig(options: FaceLandmarkConfig) {
    this._config = {
      ...this._config,
      ...options,
    };
  }

  /**
   * toggle enable & disable face landmark drawing
   * performance wise, rather than reinit the whole mediapipe, we can just decide we are going to draw landmark or not
   * @param enabled boolean
   */
  setEnabled(enabled: boolean) {
    this.changeConfig({ enabled: enabled });

    if (!enabled && this._context) {
      setTimeout(() => {
        if (this.defaultConfig.debugMode) {
          console.log(`Clearing canvas...`);
        }

        this._context.clearRect(0, 0, this._videoEl.clientWidth, this._videoEl.clientHeight);
      }, 500);
    }
  }

  /**
   * Loop function to continuously detect face landmarks
   * @todo: Limit FPS to save some resources?
   */
  async detectLoop(now: number) {
    const elapsed = now - this._timestamp;

    if (elapsed > 1000 / this._config.maxFps) {
      this._timestamp = now;

      if (this.config.enabled) {
        // Prevent detecting face and draw mesh, rather than disable/enable mediapipe/tasks-vision
        this.detect();
      }
    }

    this._frameId = window.requestAnimationFrame(this.detectLoop.bind(this));
  }

  async start() {
    const now = Date.now();

    if (!this._canvas) {
      if (this._config.debugMode) console.log(`DEBUG: Facelandmark bootstrap...`);
      await this.bootstrap();
    }

    if (this._config.debugMode) console.log(`DEBUG: Facelandmark bootstrap OK (took ${Date.now() - now}ms), starting detectLoop`);
    this.detectLoop(performance.now());
  }

  /**
   * Stop _loop requestAnimationFrame and start removing
   * all canvas related stuff
   */
  async stop() {
    window.cancelAnimationFrame(this._frameId);
    this.destroy();
  }

  /**
   * Fetch data from MediaPipe based on current frame
   * then pass it to .drawLandmarks()
   */
  detect() {
    // Resizing every frame for responsiveness
    const vWidth = this._videoEl.videoWidth;
    const vHeight = this._videoEl.videoHeight;

    this._canvas.width = this._videoEl.clientWidth;
    this._canvas.height = this._videoEl.clientHeight;

    // Calculate the scale factor
    const scaleX = this._canvas.width / vWidth;
    const scaleY = this._canvas.height / vHeight;

    this._scale = Math.max(scaleX, scaleY);

    this._offset.x = (vWidth * this._scale - this._canvas.width) / 2;
    this._offset.y = (vHeight * this._scale - this._canvas.height) / 2;

    if (this.config.debugMode) {
      console.log(
        `DEBUG: Source Dimension: ${vWidth}, ${vHeight} - Target Dimension: ${this._canvas.width}, ${this._canvas.height} - Offset: ${this._offset.x}, ${this._offset.y} - Scale: ${this._scale}`,
      );
    }

    const now = performance.now();
    const results = this._landmaker.detectForVideo(this._videoEl, now);

    if (results.faceLandmarks) {
      this.drawLandmarks(results.faceLandmarks);
    }
  }

  normalizedX(position: number) {
    const projectedPosition = position * this._videoEl.videoWidth * this._scale - this._offset.x;

    if (this._config.flipHorizontal) {
      return this._canvas.width - projectedPosition;
    }

    return projectedPosition;
  }

  normalizedY(position: number) {
    return position * this._videoEl.videoHeight * this._scale - this._offset.y;
  }

  /**
   * Draw landmark based on NormalizedLandmark[][] from .detectForVideo()
   * @param faceLandmarks
   */
  drawLandmarks(faceLandmarks: NormalizedLandmark[][]) {
    this._context.lineWidth = 0.5;
    this._context.fillStyle = this._config.dotColor;
    this._context.strokeStyle = this._config.lineColor;
    this._context.textAlign = "center";

    if (this.config.flipHorizontal) {
      // this._context.scale(-1, 1);
    }

    const dots: number[] = Array.from(new Set(MESH_ANNOTATIONS_2D.concat.apply([], MESH_ANNOTATIONS_2D)));

    const normalizedLandmarks = faceLandmarks.map((landmarks) => {
      return landmarks.map((lm) => ({
        x: this.normalizedX(lm.x),
        y: this.normalizedY(lm.y),
      }));
    });

    for (const landmarks of normalizedLandmarks) {
      // Render lines
      for (const lines of MESH_ANNOTATIONS_2D) {
        this._context.beginPath();

        this._context.moveTo(landmarks[lines[0]].x, landmarks[lines[0]].y);

        this._context.lineTo(landmarks[lines[1]].x, landmarks[lines[1]].y);

        this._context.stroke();
        this._context.closePath();
      }

      // Render dots
      for (const dot of dots) {
        const x = landmarks[dot].x;
        const y = landmarks[dot].y;

        this._context.beginPath();
        this._context.arc(x, y, 3, 0, 2 * Math.PI);
        this._context.fill();

        if (this._config.debugMode) {
          this._context.fillText(dot.toString(), x, y - 15);
        }

        this._context.closePath();
      }
    }
  }

  destroy() {
    this._videoEl.parentElement.removeChild(this._canvas);
    this._canvas = undefined;
    this._context = undefined;
  }
}
