import { JsonObject } from "../interfaces";

export default class BiomarkersResponse {
  epoch: string;
  timestamp: Date;
  heart_rate: string;
  respiratory_rate: string | null;
  heart_rate_variability: string | null;
  ans_balance: string | null;
  resonant_breathing_score: string | null;
  morning_readiness: string | null;
  acute_mental_stress_score: string | null;
  mental_health_risk: string | null;

  constructor(json: JsonObject) {
    this.epoch = json.timestamp_epoch;
    this.timestamp = new Date(json.timestamp);
    this.heart_rate = json.heart_rate;
    this.respiratory_rate = json.respiratory_rate;
    this.heart_rate_variability = json.heart_rate_variability;
    this.ans_balance = json.ans_balance;
    this.resonant_breathing_score = json.resonant_breathing_score;
    this.morning_readiness = json.morning_readiness;
    this.acute_mental_stress_score = json.acute_mental_stress_score;
    this.mental_health_risk = json.mental_health_risk;
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}
