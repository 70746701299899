export default class StreamingMetadata {
  performer: string | null;
  patient: string | null;
  userId: string | number | null = null;
  sessionId: string | number | null = null;

  constructor(
    performer: string | null = null,
    patient: string | null = null,
    userId: string | number | null = null,
    sessionId: string | number | null = null,
  ) {
    this.performer = performer;
    this.patient = patient;
    this.userId = userId;
    this.sessionId = sessionId;
  }
}
