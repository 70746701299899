import { debugLog } from "./helper";
import { BiomarkersResponse, BucketResult, BucketSummary, UnprocessableVideoResponse } from "intelliprove-streaming-sdk";
import { JsonObject } from "intelliprove-streaming-sdk/dist/interfaces";

export enum MessagingStage {
  StartNewMeasurement = "startNewMeasurement",
  RecordingStarted = "recordingStarted",
  RecordingStopped = "recordingStopped",
  Result = "results",
  Buckets = "buckets",
  BucketSummaries = "bucketSummaries",
  UserFinished = "userFinished",
  Error = "error",
  Dismiss = "dismiss",
}

interface iMessage<T> {
  stage: MessagingStage;
  uuid?: string;
  patient?: string | null;
  performer?: string | null;
  data?: T;
}

interface iMessageMetaData {
  uuid: string;
  patient: string | null;
  performer: string | null;
}

class MessagingService {
  private __targetOrigin: string;
  private metadata: iMessageMetaData;

  constructor(targetOrigin?: string) {
    this.__targetOrigin = targetOrigin ?? "*";
    this.metadata = {
      uuid: "",
      patient: null,
      performer: null,
    };
  }

  setMetadata(uuid: string, patient: string | null, performer: string | null) {
    this.metadata = {
      uuid: uuid,
      patient: patient,
      performer: performer,
    };
  }

  send(message: iMessage<any>) {
    if (window.parent) {
      console.log(`Sending postmessage "${message.stage}"`);
      debugLog(`Payload "${message.stage}": ${JSON.stringify(message)}`);
      window.parent.postMessage(message, this.__targetOrigin);
    } else {
      console.warn(`Parent is not detected, script was executed outside of iframe or standalone?`);
      debugLog(`${message.stage}: message: ${JSON.stringify(message)}`);
    }
  }

  startNewMeasurement(onboarding: boolean) {
    this.send({
      stage: MessagingStage.StartNewMeasurement,
      data: {
        onboarding: onboarding,
      },
    });
  }

  recordingStarted() {
    this.send({
      stage: MessagingStage.RecordingStarted,
      uuid: this.metadata.uuid,
    });
  }

  recordingStopped() {
    this.send({
      stage: MessagingStage.RecordingStopped,
      uuid: this.metadata.uuid,
    });
  }

  results(model: BiomarkersResponse | UnprocessableVideoResponse) {
    if (model instanceof BiomarkersResponse) {
      this.send({
        stage: MessagingStage.Result,
        ...this.metadata,
        data: model,
      });
    } else {
      this.error(model.errorDescription, model.errorCode, false);
    }
  }

  buckets(models: BucketResult[]) {
    // let data = model.map((item) => {
    //   var jsonItem = item as JsonObject
    //   delete jsonItem.history
    //   return jsonItem
    // });

    this.send({
      stage: MessagingStage.Buckets,
      ...this.metadata,
      data: {
        buckets: models,
      },
    });
  }

  bucketSummaries(models: BucketSummary[]) {
    this.send({
      stage: MessagingStage.BucketSummaries,
      ...this.metadata,
      data: {
        summaries: models,
      },
    });
  }

  userFinished() {
    this.send({
      stage: MessagingStage.UserFinished,
      data: {},
    });
  }

  dismiss() {
    this.send({
      stage: MessagingStage.Dismiss,
      data: {},
    });
  }

  error(msg: string, errorCode = -1, fatal = false) {
    this.send({
      stage: MessagingStage.Error,
      ...this.metadata,
      data: {
        code: errorCode,
        info: msg,
        fatal: fatal,
      },
    });
  }
}

export const messageService = new MessagingService();
