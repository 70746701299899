export const RETRIES = {
  upload: 5,
  process: 3,
  results: 3,
};

export const MEDIA_CONFIG = {
  targetFps: 30,
  frameSize: 220, // in pixels
  frameSizePortrait: 170, // in pixels
  frameQuality: 0.9, // 0 - 1
  frameType: "image/jpeg",
  videoElementLoadTimeout: 4000, // ms,
  cameraWidth: 1280,
  cameraHeight: 720,
};

export const SDK_CONFIG = {
  minFps: 15,
  minDuration: 15_000,
  maxDuration: 200_000,
  stopEventTime: 500,
};
