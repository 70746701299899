

export class PossibleQuestionAnswer {
	name: string;
	value: number;

	constructor(name: string, value: number) {
		this.name = name;
		this.value = value;
	}
}

export class QuestionItem {
	id: number;
	translationKey: string;
	possibleAnswers: Array<PossibleQuestionAnswer>;

	constructor(id: number, translationKey: string, possibleAnswers: Array<PossibleQuestionAnswer>) {
		this.id = id;
		this.translationKey = translationKey;
		this.possibleAnswers = possibleAnswers;
	}
}

