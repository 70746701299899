export default class StreamResolution {
  width: number = 220;
  height: number = 220;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  toString() {
    return `${this.width}x${this.height}`;
  }
}
