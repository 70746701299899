import { useTheme } from "@/context/theme";
import { MouseEventHandler } from "react";

export const IconSwapCamera = ({ filled = false, darkMode = false, ...props }: { className?: string, filled?: boolean, darkMode?: boolean, onClick: MouseEventHandler }) => {
  const theme = useTheme()

  return (
	<svg 
	  width="32"
	  height="32"
	  viewBox="0 0 32 32" 
	  fill="none" 
	  xmlns="http://www.w3.org/2000/svg"
	  {...props}
	>

	{filled ?
	  <path d="M10.1539 5.54387L9.58516 7.25012H5.5C3.56953 7.25012 2 8.81965 2 10.7501V24.7501C2 26.6806 3.56953 28.2501 5.5 28.2501H26.5C28.4305 28.2501 30 26.6806 30 24.7501V10.7501C30 8.81965 28.4305 7.25012 26.5 7.25012H22.4148L21.8461 5.54387C21.4906 4.472 20.4898 3.75012 19.3578 3.75012H12.6422C11.5102 3.75012 10.5094 4.472 10.1539 5.54387ZM23 16.0001C23 16.4814 22.6063 16.8751 22.125 16.8751H17.9305C17.5914 16.8751 17.3125 16.5962 17.3125 16.2572C17.3125 16.0931 17.3781 15.9345 17.493 15.8197L18.7891 14.5236C18.0453 13.7907 17.0445 13.3751 16 13.3751C14.95 13.3751 13.9383 13.7907 13.1945 14.54L12.1172 15.6173C11.6031 16.1314 10.7719 16.1314 10.2633 15.6173C9.75469 15.1032 9.74922 14.272 10.2633 13.7634L11.3406 12.6861C12.5766 11.4447 14.25 10.7501 16 10.7501C17.7391 10.7501 19.4125 11.4392 20.6484 12.6642L21.9445 11.3681C22.0594 11.2532 22.218 11.1876 22.382 11.1876C22.7211 11.1876 23 11.4665 23 11.8056V16.0001ZM9 19.5001C9 19.0189 9.39375 18.6251 9.875 18.6251H14.0695C14.4086 18.6251 14.6875 18.904 14.6875 19.2431C14.6875 19.4072 14.6219 19.5657 14.507 19.6806L13.2109 20.9767C13.9547 21.7095 14.9555 22.1251 16 22.1251C17.05 22.1251 18.0617 21.7095 18.8055 20.9603L19.8828 19.8829C20.3969 19.3689 21.2281 19.3689 21.7367 19.8829C22.2453 20.397 22.2508 21.2282 21.7367 21.7368L20.6594 22.8142C19.4234 24.0556 17.75 24.7501 16 24.7501C14.2609 24.7501 12.5875 24.0611 11.3516 22.8361L10.0555 24.1322C9.94063 24.247 9.78203 24.3126 9.61797 24.3126C9.27891 24.3126 9 24.0337 9 23.6947V19.5001Z" 
		fill={darkMode ? theme.value.brand_tertiary : theme.value.brand_secondary} />
	  :
	  <path d="M12.4508 3.98938C11.3188 3.98938 10.318 4.71126 9.9625 5.78313L9.38828 7.48938H5.5C3.56953 7.48938 2 9.05891 2 10.9894V24.9894C2 26.9198 3.56953 28.4894 5.5 28.4894H26.5C28.4305 28.4894 30 26.9198 30 24.9894V10.9894C30 9.05891 28.4305 7.48938 26.5 7.48938H22.6117L22.043 5.78313C21.682 4.71126 20.6812 3.98938 19.5492 3.98938H12.4508ZM11.6195 6.33547C11.7398 5.98001 12.0734 5.73938 12.4508 5.73938H19.5492C19.9266 5.73938 20.2602 5.98001 20.3805 6.33547L21.1461 8.63782C21.2664 8.99329 21.6 9.23391 21.9773 9.23391H26.5C27.468 9.23391 28.25 10.0159 28.25 10.9839V24.9894C28.25 25.9573 27.468 26.7394 26.5 26.7394H5.5C4.53203 26.7394 3.75 25.9573 3.75 24.9894V10.9894C3.75 10.0214 4.53203 9.23938 5.5 9.23938H10.0227C10.4 9.23938 10.7336 8.99875 10.8539 8.64329L11.6195 6.34094V6.33547ZM21.25 17.1144C21.7313 17.1144 22.125 16.7206 22.125 16.2394V12.7394C22.125 12.2581 21.7313 11.8644 21.25 11.8644C20.7687 11.8644 20.375 12.2581 20.375 12.7394V14.1284L19.6039 13.3573C18.6523 12.4003 17.3508 11.8644 16 11.8644C13.8672 11.8644 11.9914 12.9527 10.8977 14.6042C10.6297 15.0089 10.7391 15.5503 11.1438 15.8183C11.5484 16.0863 12.0898 15.9769 12.3578 15.5722C13.1398 14.3909 14.4797 13.6144 16 13.6144C16.8859 13.6144 17.7391 13.9698 18.368 14.5933L19.1391 15.3644H17.75C17.2688 15.3644 16.875 15.7581 16.875 16.2394C16.875 16.7206 17.2688 17.1144 17.75 17.1144H21.25ZM10.75 18.8644C10.2688 18.8644 9.875 19.2581 9.875 19.7394V23.2394C9.875 23.7206 10.2688 24.1144 10.75 24.1144C11.2312 24.1144 11.625 23.7206 11.625 23.2394V21.8503L12.3961 22.6214C13.3477 23.5784 14.6492 24.1144 16 24.1144C18.1328 24.1144 20.0086 23.0261 21.1023 21.3745C21.3703 20.9698 21.2609 20.4284 20.8562 20.1605C20.4516 19.8925 19.9102 20.0019 19.6422 20.4066C18.8602 21.5878 17.5203 22.3644 16 22.3644C15.1141 22.3644 14.2609 22.0089 13.632 21.3855L12.8609 20.6144H14.25C14.7312 20.6144 15.125 20.2206 15.125 19.7394C15.125 19.2581 14.7312 18.8644 14.25 18.8644H10.75Z" 
		fill={darkMode ? theme.value.brand_tertiary : theme.value.brand_secondary} />
	}
	</svg>
  );
};
