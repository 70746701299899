import { useTheme } from "@/context/theme";
import {SVGProps } from "react";

export const IconHeartRate = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M60.0024 56.4697L44.4724 40.7282C47.1786 38.2002 49.2304 34.8834 50.2209 30.9929C51.5299 25.8114 50.7693 20.4328 48.0807 15.8251C45.3921 11.2174 41.094 7.95434 35.9822 6.62761C30.8527 5.30088 25.5463 6.07182 21.0005 8.797C16.4547 11.5222 13.2355 15.8789 11.9266 21.0603C11.8027 21.5444 12.0858 22.0285 12.5633 22.154C13.0409 22.2795 13.5185 21.9926 13.6423 21.5085C14.8274 16.7932 17.7636 12.831 21.9026 10.3389C26.0415 7.8647 30.8703 7.14755 35.5223 8.36671C40.1742 9.56794 44.0832 12.5441 46.5419 16.7395C48.9828 20.9348 49.6903 25.8294 48.4875 30.5446C46.0289 40.28 36.2121 46.1786 26.6076 43.6865C20.293 42.0549 15.3403 37.0528 13.6777 30.6701C13.5539 30.1861 13.0763 29.8992 12.5987 30.0247C12.1211 30.1502 11.8381 30.6343 11.9619 31.1184C13.7838 38.1285 19.2317 43.6147 26.1654 45.4076C27.8103 45.8379 29.4376 46.0351 31.0649 46.0351C35.4869 46.0351 39.6966 44.5291 43.1104 41.8757L58.7465 57.7247C58.9234 57.904 59.1533 57.9937 59.3656 57.9937C59.5779 57.9937 59.8255 57.904 59.9847 57.7247C60.3384 57.3661 60.3384 56.8103 59.9847 56.4518L60.0024 56.4697Z"
        fill={theme.value.support_500}
      />
      <path
        d="M20.3814 25.8832L23.406 20.5762C23.4945 20.4328 23.6714 20.379 23.8129 20.4687C23.8836 20.5045 23.919 20.5583 23.9544 20.63L27.0144 30.706C27.1736 31.2439 27.5273 31.6742 28.0226 31.9431C28.3233 32.1045 28.6593 32.1941 28.9777 32.1941C29.19 32.1941 29.3846 32.1582 29.5968 32.1045C30.1274 31.9431 30.5873 31.5487 30.835 31.0466L34.5141 23.6062C34.5848 23.4628 34.7617 23.409 35.027 23.5703L37.7156 27.6402C38.3523 28.5904 39.6259 28.8593 40.581 28.2318C40.7756 28.1063 40.9348 27.945 41.0763 27.7657L44.7377 23.0145C45.0384 22.6201 44.9676 22.0643 44.5785 21.7595C44.1894 21.4547 43.641 21.5265 43.3403 21.9209L39.6259 26.7437C39.4844 26.8334 39.3075 26.7975 39.219 26.6541L36.5482 22.5843C36.3536 22.2795 36.0706 22.0285 35.7522 21.8671C35.2569 21.6161 34.6909 21.5802 34.178 21.7595C33.6473 21.9388 33.2405 22.3153 32.9929 22.7994L29.3138 30.2399C29.3138 30.2399 29.2077 30.3654 29.1369 30.3833C29.0131 30.4371 28.8185 30.3295 28.7655 30.1861L25.7055 20.1101C25.5463 19.6081 25.2102 19.1778 24.768 18.9268C23.7775 18.3531 22.5216 18.6937 21.9556 19.6977L18.931 24.9867C18.8779 25.0764 18.7718 25.1301 18.6834 25.1301H5.1344C4.63913 25.1301 4.25 25.5246 4.25 26.0266C4.25 26.5286 4.63913 26.923 5.1344 26.923H18.6126C19.3378 26.923 20.0276 26.5286 20.3991 25.8832H20.3814Z"
        fill={theme.value.support_500}
      />
    </svg>
  );
};
