export default class WebsocketMessage {
  frame: string;
  timestamp: number;
  streamId: string;
  stop: boolean;
  version: number;

  constructor(frame: string, streamId: string, stop: boolean = false, version: number = 1) {
    this.frame = frame;
    this.streamId = streamId;
    this.timestamp = Date.now();
    this.stop = stop;
	this.version = version;
  }
}
