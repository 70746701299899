import { useTheme } from "@/context/theme";
import { iIconOnboarding } from "./type";

export const IconOnboardingMobile2 = ({ ...props }: iIconOnboarding) => {
  const theme = useTheme();

  return (
    <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M78 37.5C70.5469 37.5 64.5 43.5469 64.5 51V159C64.5 166.453 70.5469 172.5 78 172.5H132C139.453 172.5 145.5 166.453 145.5 159V51C145.5 43.5469 139.453 37.5 132 37.5H78ZM60 51C60 41.0719 68.0719 33 78 33H132C141.928 33 150 41.0719 150 51V159C150 168.928 141.928 177 132 177H78C68.0719 177 60 168.928 60 159V51ZM91.5001 154.5H118.5C119.738 154.5 120.75 155.513 120.75 156.75C120.75 157.988 119.738 159 118.5 159H91.5001C90.2626 159 89.2501 157.988 89.2501 156.75C89.2501 155.513 90.2626 154.5 91.5001 154.5Z"
        fill={theme.value.support_500}
      />
      <path
        d="M59.9991 199H42C39.7909 199 38 197.209 38 195V177"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M59.9996 188.001H51C49.8954 188.001 49 187.106 49 186.001V177.001"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M150 11H168C170.209 11 172 12.7909 172 15V32.9997"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M150 22H159C160.105 22 161 22.8954 161 24V32.9998"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};
