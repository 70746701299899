import { JsonObject } from "../interfaces";

export default class StreamStatus {
  uuid: string;
  done: boolean = false;
  duration: number | null = null;
  startTime: number | null = null;
  stopTime: number | null = null;

  constructor(json: JsonObject) {
    this.uuid = json.streamId;
  }

  get remaining(): number | null {
    return this.stopTime !== null ? Math.round(this.stopTime - Date.now()) : null;
  }

  resetTime() {
    this.stopTime = Date.now() + this.duration;
  }
}
