import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type GlobaErrorState = {
  error: boolean;
  metadata?: {
    title?: string;
    text?: string;
  };
  dismissable?: boolean;
  dismissText?: string;
};

const initialState: GlobaErrorState = {
  error: false,
  metadata: undefined,
  dismissable: true,
  dismissText: undefined
};

export const globalErrorSlice = createSlice({
  name: "globalError",
  initialState,
  reducers: {
    setGlobalError: (state, action: PayloadAction<GlobaErrorState>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    resetGlobalError: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGlobalError, resetGlobalError } = globalErrorSlice.actions;

export default globalErrorSlice.reducer;
