import { useTheme } from "@/context/theme";

export const IconClose = ({ ...props }) => {
  const theme = useTheme();

  return (
    <svg width="18" height="18" viewBox="-2 -2 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.6902 1.79376C14.1013 1.38264 14.1013 0.717845 13.6902 0.311097C13.2791 -0.0956506 12.6143 -0.100024 12.2075 0.311097L7.00291 5.51572L1.79391 0.306724C1.38279 -0.104398 0.717998 -0.104398 0.31125 0.306724C-0.095498 0.717845 -0.0998717 1.38264 0.31125 1.78938L5.51587 6.99401L0.306876 12.203C-0.104245 12.6141 -0.104245 13.2789 0.306876 13.6857C0.717998 14.0924 1.38279 14.0968 1.78954 13.6857L6.99416 8.48104L12.2032 13.69C12.6143 14.1012 13.2791 14.1012 13.6858 13.69C14.0926 13.2789 14.0969 12.6141 13.6858 12.2074L8.4812 7.00276L13.6902 1.79376Z" />
    </svg>
  );
};
