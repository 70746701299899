import QualityResponse from "../models/quality_response";

export default class QualityError extends Error {
  quality: QualityResponse;

  constructor(msg: string, qr: QualityResponse) {
    super(msg);
    this.quality = qr;

    Object.setPrototypeOf(this, QualityError.prototype);
  }
}
