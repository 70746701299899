import { ReactNode } from "react";
import { shallowEqual } from "react-redux";

import { useAppDispatch, useAppSelector } from "@/hooks";
import { resetGlobalError } from "@/store/reducers/globalError.reducer";
import { AppModal } from "@/components/Modal/Modal";

export const ModalProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [globalError] = useAppSelector(
    (state) => [state.globalError],
    shallowEqual
  );
  const dispatch = useAppDispatch();

  return (
    <>
      {children}

      <AppModal 
        isOpen={globalError.error}
        title={globalError.metadata?.title}
        text={globalError.metadata?.text}
        dismissText={globalError.dismissText}
        onDismiss={() => dispatch(resetGlobalError())}
      />
    </>
  );
};
