import cx from "classnames";
import { Button, ButtonType } from "@/components/Button/Button";
import React, { ReactNode } from "react";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

export interface iOnboardingSlideItem {
  image: JSX.Element;
  text: string | ReactNode;
  customComponent?: React.ReactElement;
  hideSkipButton: boolean;
  onSkip?: MouseEventHandler;
  className?: string;
}

export const OnboardingSlideItem = ({
  image,
  text,
  customComponent,
  hideSkipButton,
  className = "",
  onSkip = () => {},
}: iOnboardingSlideItem) => {
  const { t } = useTranslation();

  return (
    <div className={cx(className)}>
      <div className="flex flex-col items-center sm:justify-center h-full m-auto select-none">
        <div className="w-full text-center flex-grow flex justify-evenly items-center flex-col">
          <div className="w-full flex justify-center">{image}</div>
          <span className="lg:max-w-[600px] text-primary text-support-500 dark:text-support-100 font-bold text-[21px] text-lg lg:text-xl sm:my-5 text-center">
            {text}
          </span>
        </div>

        <div className="hidden sm:flex mt-2 mb-8 sm:h-[unset] justify-self-end">
          {customComponent
            ? customComponent
            : !hideSkipButton && (
                <Button
                  text={t("skip_instruction", "SKIP INSTRUCTIONS")}
                  type={ButtonType.Outline}
                  textStyles={{ textDecoration: "underline", letterSpacing: 0.5 }}
                  onClick={onSkip}
                  className="sm:block uppercase"
                />
              )}
        </div>
      </div>
    </div>
  );
};
