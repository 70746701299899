export const MESH_ANNOTATIONS_2D = [
  [10, 133],
  [133, 362],
  [10, 362],
  [103, 133],
  [10, 103],
  [332, 362],
  [10, 332],
  [235, 362],
  [133, 235],
  [294, 362],
  [133, 294],
  [0, 235],
  [0, 132],
  [132, 235],
  [0, 294],
  [0, 361],
  [294, 361],
  [235, 61],
  [132, 61],
  [149, 152],
  [152, 61],
  [149, 61],
  [172, 61],
  [149, 172],
  [132, 172],
  [132, 149],
  [149, 17],
  [152, 17],
  [17, 378],
  [17, 397],
  [378, 397],
  [17, 172],
  [152, 378],
  [291, 378],
  [291, 397],
  [291, 361],
  [361, 397],
  [127, 33],
  [127, 235],
  [235, 33],
  [103, 33],
  [133, 33],
  [10, 21],
  [10, 33],
  [21, 33],
  [103, 21],
  [127, 21],
  [235, 93],
  [33, 93],
  [127, 93],
  [132, 93],
  [132, 33],
  [132, 133],
  [251, 263],
  [251, 332],
  [263, 332],
  [251, 356],
  [263, 356],
  [10, 263],
  [10, 251],
  [263, 323],
  [323, 356],
  [263, 294],
  [263, 361],
  [361, 362],
  [294, 356],
  [294, 323],
  [323, 361],
];
