import { AppModal } from "@/components/Modal/Modal"
import { useTranslation } from "react-i18next"

export const NotFound = () => {
  const { t } = useTranslation()

  return (
    <>
      <AppModal
        isOpen={true}
        title={t('error_oops')}
        text={t('page_not_found', 'Page not found!')}
        dismissable={false}
      />
    </>
  )
}