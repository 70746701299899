import { JsonObject } from "../interfaces";
import { LiveErrorAction } from "./live_error";

export default class LiveQualityCheck {
  error_code: number;
  info: string;
  action: LiveErrorAction;
  resetOn: number | null;

  constructor(json: JsonObject) {
    this.error_code = parseInt(json.feedbackCode);
    this.info = json.feedbackPrompt;
    this.action = json.action as LiveErrorAction;
    this.resetOn = json.reset_on ?? null;
  }
}
