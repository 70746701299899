import { useTheme } from "@/context/theme";
import { SVGProps } from "react";

export const IconCircleX = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      width="88"
      height="89"
      viewBox="0 0 88 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44 6.21875C54.1528 6.21875 63.8898 10.2519 71.0689 17.4311C78.2481 24.6102 82.2812 34.3472 82.2812 44.5C82.2812 54.6528 78.2481 64.3898 71.0689 71.5689C63.8898 78.7481 54.1528 82.7812 44 82.7812C33.8472 82.7812 24.1102 78.7481 16.9311 71.5689C9.75194 64.3898 5.71875 54.6528 5.71875 44.5C5.71875 34.3472 9.75194 24.6102 16.9311 17.4311C24.1102 10.2519 33.8472 6.21875 44 6.21875ZM44 88.25C55.6032 88.25 66.7312 83.6406 74.9359 75.4359C83.1406 67.2312 87.75 56.1032 87.75 44.5C87.75 32.8968 83.1406 21.7688 74.9359 13.5641C66.7312 5.35936 55.6032 0.75 44 0.75C32.3968 0.75 21.2688 5.35936 13.0641 13.5641C4.85936 21.7688 0.25 32.8968 0.25 44.5C0.25 56.1032 4.85936 67.2312 13.0641 75.4359C21.2688 83.6406 32.3968 88.25 44 88.25ZM31.1313 31.6313C30.0718 32.6909 30.0718 34.4341 31.1313 35.4937L40.1377 44.5L31.1313 53.5064C30.0718 54.5659 30.0718 56.3091 31.1313 57.3686C32.1909 58.4282 33.9341 58.4282 34.9937 57.3686L44 48.3623L53.0064 57.3686C54.0659 58.4282 55.8091 58.4282 56.8686 57.3686C57.9282 56.3091 57.9282 54.5659 56.8686 53.5064L47.8623 44.5L56.8686 35.4937C57.9282 34.4341 57.9282 32.6909 56.8686 31.6313C55.8091 30.5718 54.0659 30.5718 53.0064 31.6313L44 40.6377L34.9937 31.6313C33.9341 30.5718 32.1909 30.5718 31.1313 31.6313Z"
        fill="#092187"
      />
    </svg>
  );
};
