const ErrorType = {
  Connection: 0,
  Configuration: 1,
  SDK: 2,
  UI: 3,
  Device: 4,
  Other: 99,
};

export default ErrorType;
