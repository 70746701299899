import { BaseLayout } from "@/components/Layout/BaseLayout";
import { useTheme } from "@/context/theme";
import { ReactComponent as IcRing } from "@/images/ic-ring.svg";

export const Splashscreen = () => {
  const theme = useTheme();

  return (
    <>
      <BaseLayout>
        <div className="fixed left-1/2 top-1/2 ml-[-12px] flex z-10">
          <IcRing className="items-center w-full m-auto stroke-support-500" />
        </div>
      </BaseLayout>
    </>
  );
};
