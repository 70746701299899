import { JsonObject } from "../interfaces";

export default class UnprocessableVideoResponse {
  errorCode: number;
  errorType: string;
  errorDescription: string;

  constructor(json: JsonObject) {
    this.errorCode = json.errorCode;
    this.errorType = json.errorType;
    this.errorDescription = json.errorDescription;
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}
