import { useTheme } from "@/context/theme";
import { toInteger } from "lodash";

export const IconQuestion = ({ darkMode = false }: { darkMode?: boolean }) => {
  const theme = useTheme();

  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.03239 3.10742C1.47672 1.42578 2.9943 0.25 4.73746 0.25H8.64079C11.0675 0.25 13.0158 2.23242 13.0158 4.64551C13.0158 6.28613 12.0998 7.80371 10.63 8.55566L8.09391 9.84082V11.1875C8.09391 11.7891 7.60172 12.2812 7.00016 12.2812C6.3986 12.2812 5.90641 11.7891 5.90641 11.1875V9.1709C5.90641 8.76074 6.13883 8.38477 6.50114 8.19336L9.632 6.59375C10.3634 6.22461 10.8283 5.45899 10.8283 4.63184C10.8283 3.41504 9.84391 2.42383 8.64079 2.42383H4.73746C3.99235 2.42383 3.3361 2.92969 3.15153 3.64746L3.13786 3.69531C2.98746 4.27637 2.3859 4.63184 1.80485 4.47461C1.22379 4.31738 0.868325 3.72266 1.02555 3.1416L1.03922 3.09375L1.03239 3.10742ZM5.35954 15.5625C5.35954 15.1274 5.53239 14.7101 5.84006 14.4024C6.14774 14.0947 6.56504 13.9219 7.00016 13.9219C7.43528 13.9219 7.85258 14.0947 8.16026 14.4024C8.46793 14.7101 8.64079 15.1274 8.64079 15.5625C8.64079 15.9976 8.46793 16.4149 8.16026 16.7226C7.85258 17.0303 7.43528 17.2031 7.00016 17.2031C6.56504 17.2031 6.14774 17.0303 5.84006 16.7226C5.53239 16.4149 5.35954 15.9976 5.35954 15.5625Z"
        fill={
          !!(toInteger(darkMode ?? true) ^ toInteger(theme.value.dark_mode ?? false))
            ? theme.value.brand_tertiary
            : theme.value.brand_secondary
        }
      />
    </svg>
  );
};
