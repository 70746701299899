import { JsonObject } from "../interfaces";

export class BucketSummary {
  name: string;
  summary: string;
  tip: string | null;

  constructor(json: JsonObject) {
    this.name = json.name;
    this.summary = json.summary;
    this.tip = json.tip ?? null;
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}

export default class BucketFeedbackResponse {
  uuid: string;
  summaries: Array<BucketSummary>;

  constructor(json: JsonObject) {
    this.uuid = json.uuid;
    this.summaries = json.summaries.map((s: JsonObject) => new BucketSummary(s));
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}
