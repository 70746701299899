import { JsonObject } from "../interfaces";

export default class ResultsCountResponse {
  measurement_count: number;
  max_measurement_count: number;

  constructor(json: JsonObject) {
    this.measurement_count = json.measurement_count;
    this.max_measurement_count = json.max_measurement_count;
  }

  maxReached(): boolean {
    return this.measurement_count >= this.max_measurement_count;
  }
}
