import { useTheme } from "@/context/theme";
import { iIconOnboarding } from "./type";

export const IconOnboardingMobile7 = ({ ...props }: iIconOnboarding) => {
  const theme = useTheme();

  return (
    <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="M82.4993 137.439H74.8564C73.7519 137.439 72.8564 136.544 72.8564 135.439V127.945"
          stroke={theme.value.brand_primary}
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M127.5 137.439H135.143C136.247 137.439 137.143 136.544 137.143 135.439V127.945"
          stroke={theme.value.brand_primary}
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M82.4993 49.6152H74.8564C73.7519 49.6152 72.8564 50.5107 72.8564 51.6152V59.1097"
          stroke={theme.value.brand_primary}
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M127.5 49.6152H135.143C136.247 49.6152 137.143 50.5107 137.143 51.6152V59.1097"
          stroke={theme.value.brand_primary}
          strokeWidth="4"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M78 37.5C70.5469 37.5 64.5 43.5469 64.5 51V159C64.5 166.453 70.5469 172.5 78 172.5H132C139.453 172.5 145.5 166.453 145.5 159V51C145.5 43.5469 139.453 37.5 132 37.5H78ZM60 51C60 41.0719 68.0719 33 78 33H132C141.928 33 150 41.0719 150 51V159C150 168.928 141.928 177 132 177H78C68.0719 177 60 168.928 60 159V51Z"
        fill={theme.value.support_500}
      />
      <g>
        <rect x="70.4463" y="146.935" width="69.1071" height="20.5714" rx="8" fill={theme.value.support_300} />
      </g>
      <ellipse cx="104.195" cy="157.219" rx="6.42857" ry="6.32967" fill={theme.value.functional_tertiary_500} />
      <ellipse cx="104.195" cy="157.219" rx="8.03571" ry="7.91209" stroke="white" />
      <g>
        <path
          d="M106.241 103.459C118.801 103.459 128.983 93.3744 128.983 80.9355C128.983 68.4966 118.801 58.4121 106.241 58.4121C93.6807 58.4121 83.498 68.4966 83.498 80.9355C83.498 93.3744 93.6807 103.459 106.241 103.459ZM106.241 61.2275C117.232 61.2275 126.14 70.0504 126.14 80.9355C126.14 91.8206 117.232 100.643 106.241 100.643C95.2496 100.643 86.3409 91.8206 86.3409 80.9355C86.3409 70.0504 95.2496 61.2275 106.241 61.2275Z"
          fill={theme.value.support_500}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.582 121.702L82.5485 121.724C81.9335 122.121 81.1026 121.96 80.6927 121.364C80.2827 120.769 80.4489 119.964 81.0638 119.567C83.5203 117.98 90.6568 113.422 106.277 113.422C121.896 113.422 129.033 117.98 131.489 119.567C132.104 119.964 132.27 120.769 131.861 121.364C131.451 121.96 130.62 122.121 130.005 121.724L129.971 121.702C127.804 120.302 121.167 116.015 106.277 116.015C91.3861 116.015 84.7493 120.302 82.582 121.702Z"
          fill={theme.value.support_500}
        />
      </g>

      <path d="M60 200L150 200" stroke={theme.value.functional_tertiary_500} strokeWidth="5" strokeLinecap="round" />
    </svg>
  );
};
