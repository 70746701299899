import { JsonObject } from "../interfaces";

export default class LiveResults {
  heart_rate: number;
  respiratory_rate: number | null;
  resonant_breathing_score: number | null;
  final: boolean = false;

  constructor(json: JsonObject) {
    this.heart_rate = json.heart_rate;
    this.respiratory_rate = json.respiratory_rate;
    this.resonant_breathing_score = json.resonant_breathing_score;
    this.final = json.final;
  }
}
