import "./Modal.scss";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { IconCircleExclamation } from "../Icons/icon_circle_exclamation";
import { Button, ButtonType } from "../Button/Button";
import { debugLog } from "@/utils/helper";

export interface iAppModalAction {
  text: string;
  onPress: VoidFunction;
}

export interface iAppModal {
  isOpen?: boolean;
  className?: string;
  title?: string;
  text?: string;
  onDismiss?: Function;
  dismissable?: boolean;
  dismissText?: string;
  action?: iAppModalAction;
  icon?: React.ReactNode;
}

export const AppModal = ({
  isOpen = false,
  title,
  text,
  onDismiss,
  dismissable = true,
  dismissText,
  className = "",
  action,
  icon,
}: iAppModal) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Error!"
      // Use class names instead, to make it easier to manage
      className={`modal w-[calc(100%-40px)] sm:max-w-[400px] sm:min-w-[400px] ${className}`}
    >
      <div className="flex flex-col rounded-[20px] justify-center items-center">
        <div className="text-center my-5">{icon ?? <IconCircleExclamation />}</div>

        <div className="text-support-500 dark:text-support-100 text-2xl font-semibold my-4 text-center">{title ?? t("error_oops")}</div>

        <div className="text-center text-support-500 dark:text-support-100 text-md">{text ?? t("error_oops_description")}</div>

        {dismissable && (
          <div className="mt-10">
            {action && (
              <Button
                text={action.text}
                type={ButtonType.Accent}
                containerClass="uppercase px-[32px] py-1"
                onClick={action.onPress}
                textStyles={{ fontSize: "14px" }}
              />
            )}

            {onDismiss && (
              <Button
                text={dismissText ?? t("close")}
                type={ButtonType.Outline}
                textStyles={{ fontSize: "12px" }}
                containerClass={cx("uppercase mt-2")}
                onClick={() => onDismiss()}
              />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
