import { AppContext } from "@/AppBootstrap";
import { debugLog } from "@/utils/helper";
import { IntelliProveService } from "@/utils/intelliprove.service";
import { FC, useState, useContext, useCallback, createContext } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export enum Cookie {
  Language = "language",
}

const useValue = () => {
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies([Cookie.Language]);
  const [language, setLanguage] = useState<string>();
  const appState = useContext(AppContext);

  const syncTranslations = useCallback(
    async (sdk: IntelliProveService, lang: string) => {
      try {
        debugLog(`useLanguage: sync translations -> ${lang}`);
        const translations = await sdk.instance?.getPluginTranslations(lang);

        if (!translations || !translations.keys) {
          throw new Error(`Translations empty!`);
        }

        if (translations) {
          debugLog(`useLanguage: override translation from sdk`, lang, Object.keys(translations.keys).length);
          i18n.addResourceBundle(lang, "translation", translations.keys);
        }
      } catch (e) {
        debugLog(`useLanguage: failed to get translation from SDK, using default translations`, e);
      }
    },
    [appState],
  );

  const changeLanguage = useCallback(
    async (sdk: IntelliProveService, lang: string) => {
      await syncTranslations(sdk, lang);
      // setCookie(Cookie.Language, lang);
      setLanguage(lang);
    },
    [setCookie],
  );

  return {
    language,
    changeLanguage,
    syncTranslations,
  };
};

const LanguageContext = createContext({} as ReturnType<typeof useValue>);
const useLanguage = () => useContext(LanguageContext);
const LanguageProvider: FC<any> = (props) => {
  return <LanguageContext.Provider value={useValue()} {...props} />;
};

export { LanguageProvider, useLanguage };
