import { IconFromName } from "@/components/AssetsManager/AssetsManager";
import { iResultBiomarkerCard } from "../ResultBiomarkerCard.interface";
import { useTranslation } from "react-i18next";
import cx from "classnames";

export interface iResultBiomarkerEmptyCard extends iResultBiomarkerCard {
  text?: string;
}

export const BiomarkerEmptyCard = ({
  biomarker,
  text,
}: iResultBiomarkerEmptyCard) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-row items-center self-center relative justify-center m-auto w-full sm:w-[166px]">
        <div className="sm:mx-5 w-full m-auto">
          <IconFromName
            name={biomarker.name}
            className="opacity-10 m-auto w-[80px] sm:w-[104px]"
          />
        </div>

        <span
          className={cx(
            "text-support-500 text-center opacity-80 absolute",
            t(`${biomarker.name}_null_description`, "No data measured").length >
              53
              ? "text-[3vw] sm:text-sm"
              : "text-[3vw] sm:text-base"
          )}
        >
          {t(`${biomarker.name}_null_description`, "No data measured")}
        </span>
      </div>
    </>
  );
};
