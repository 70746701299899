import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import Typography from "../Typography/Typography";
import { LiveResults } from "intelliprove-streaming-sdk";

import { ReactComponent as IcheartWhite } from "@/images/ic-heart-white.svg";
import { ReactComponent as IcRespiratoryWhite } from "@/images/ic-respiratory-white.svg";
import { ReactComponent as IcResonanceBreathingScoreWhite } from "@/images/ic-resonance-breathing-score-white.svg";

import { ReactComponent as IcheartBlack } from "@/images/ic-heart-black.svg";
import { ReactComponent as IcRespiratoryBlack } from "@/images/ic-respiratory-black.svg";
import { ReactComponent as IcResonanceBreathingScoreBlack } from "@/images/ic-resonance-breathing-score-black.svg";

export interface iLiveBiometricProps {
  result: LiveResults;
  darkMode?: boolean;
}

export const LiveBiometric = ({
  result,
  darkMode = true,
}: iLiveBiometricProps) => {
  const { t } = useTranslation();

  const biometrics = [
    {
      name: 'heart_rate',
      range: [80, 150],
      unit: "bpm",
      icon: darkMode ? <IcheartWhite /> : <IcheartBlack />,
    },
    {
      name: 'respiratory_rate',
      range: [10, 30],
      unit: "bpm",
      icon: darkMode ? <IcRespiratoryWhite /> : <IcRespiratoryBlack />,
    },
    {
      name: 'resonant_breathing_score',
      range: [10, 30],
      unit: "%",
      icon: darkMode ? (
        <IcResonanceBreathingScoreWhite />
      ) : (
        <IcResonanceBreathingScoreBlack />
      ),
    }
  ];

  
  return (
    <>
      {biometrics.map((biometric) => {
        if (!Object.keys(result).includes(biometric.name)) {
          return null;
        }

        return (
          <div
            key={biometric.name}
            className="flex flex-col justify-between p-[8px] sm:p-[16px] rounded-[12px] min-h-[87px] w-[140px]"
            style={{
              backgroundColor: darkMode
                ? `rgba(var(--rgb-brand-secondary), 0.64)`
                : `rgba(255, 255, 255, 0.75)`,
            }}
          >
            <Typography.Text
              className={cx(
                "self-center text-center text-[1.3vh] sm:text-[13px]",
                darkMode ? "text-[#fff]" : "text-[#000]"
              )}
            >
              {t(biometric.name)}
            </Typography.Text>
            <div className="flex flex-row items-center justify-between mt-[8px]">
              <div className="w-[2.5vh] sm:w-[28px]">
                {biometric.icon}
              </div>
              <Typography.Title
                className={cx(
                  "text-[3vh] sm:text-[26px]",
                  darkMode ? "text-[#fff]" : "text-[#000]"
                )}
              >
                {result[biometric.name as keyof LiveResults]}
              </Typography.Title>
              <Typography.Text
                className={cx(
                  "text-[1.2vh] sm:text-[13px]",
                  darkMode ? "text-[#fff]" : "text-[#000]"
                )}
              >
                {biometric.unit}
              </Typography.Text>
            </div>
          </div>
        );
      })}
    </>
  );
};
