import IntelliProveMediaError, { FpsTooLowMediaError, ResolutionMediaError } from "../exceptions/intelliprove_media_exception";
import StreamResolution from "../models/stream_resolution";

export default class VideoStreamValidation {
  static validate(videoStream: null | MediaStream, isPortrait: boolean) {
    if (videoStream === null) {
      throw new IntelliProveMediaError("Stream is not set yet, cannot validate framerate!");
    }

    const videoTrack: MediaStreamTrack = this.getTrackFromStream(videoStream);
    this._validateSupportedFps(videoTrack);

    if (!isPortrait) {
      // Only validate resolution on desktop devices
      this._validateResolution(videoTrack);
    }
  }

  static getTrackFromStream(videoStream: MediaStream) {
    const videoTracks = videoStream.getVideoTracks();
    if (videoTracks.length === 0) {
      throw new IntelliProveMediaError("Stream does not contain any video tracks!");
    }

    const videoTrack: MediaStreamTrack = videoTracks[0];
    return videoTrack;
  }

  static getActualStreamResolution(videoTrack: MediaStreamTrack) {
    const settings: MediaTrackSettings = videoTrack.getSettings();

    let height = settings.height;
    let width = settings.width;
    return new StreamResolution(width, height);
  }

  static getMaxSupportedFramerate(videoTrack: MediaStreamTrack) {
    // Use getCapabilities first
    let capabilities: MediaTrackCapabilities;
    try {
      capabilities = videoTrack.getCapabilities();
    } catch (error) {
      console.error(error);
    }

    if (capabilities && capabilities.frameRate && capabilities.frameRate.max !== null) {
      return capabilities.frameRate.max;
    }

    const videoSettings: MediaTrackSettings = videoTrack.getSettings();
    if (videoSettings && videoSettings.frameRate) {
      return videoSettings.frameRate;
    }

    return -1;
  }

  static _validateSupportedFps(videoTrack: MediaStreamTrack) {
    const requiredFps = 16;
    let maxSupportedFramerate = this.getMaxSupportedFramerate(videoTrack);

    if (maxSupportedFramerate == -1) {
      throw new IntelliProveMediaError("Unable to determine device max supported frame rate!");
    }

    let success = maxSupportedFramerate >= requiredFps;
    if (!success) throw new FpsTooLowMediaError(requiredFps, maxSupportedFramerate);
  }

  static _validateResolution(videoTrack: MediaStreamTrack) {
    let required = new StreamResolution(640, 480);
    let actual = this.getActualStreamResolution(videoTrack);
    let success = actual.height > required.height && actual.width > required.width;

    if (!success) throw new ResolutionMediaError(required.toString(), actual.toString());
  }
}
