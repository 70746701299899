export default class IntelliProveMediaError extends Error {
  fixable: boolean = false;

  constructor(msg: string, fixable: boolean = false) {
    super(msg);
    this.fixable = fixable;

    Object.setPrototypeOf(this, IntelliProveMediaError.prototype);
  }
}

export class NoCameraMediaError extends IntelliProveMediaError {
  constructor() {
    super("No camera found matching the media stream constraints.");

    Object.setPrototypeOf(this, NoCameraMediaError.prototype);
  }
}

export class FpsTooLowMediaError extends IntelliProveMediaError {
  constructor(requiredFps: number, maxSupportedFps: number) {
    let msg = `The current device does not support the minimum required framerate!
              Required: ${requiredFps}, max supported: ${maxSupportedFps}`;
    super(msg);

    Object.setPrototypeOf(this, FpsTooLowMediaError.prototype);
  }
}

export class ResolutionMediaError extends IntelliProveMediaError {
  constructor(requiredResolution: string, currentResolution: string) {
    let msg = `The current device does not support the minimum required resolution!
              Required: ${requiredResolution}, Current: ${currentResolution}`;
    super(msg);

    Object.setPrototypeOf(this, ResolutionMediaError.prototype);
  }
}
