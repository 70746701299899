import { MouseEventHandler } from "react";
import cx from "classnames";
import { ButtonArc } from "../ButtonArc/ButtonArc";

import "./MeasurementButton.scss";
import { IconFlash, IconFlashOn } from "../Icons";
import { useTheme } from "@/context/theme";
import { IconSwapCamera } from "../Icons/icon_swap_camera";
import { toInteger } from "lodash";

export enum MeasurementButtonTheme {
  default,
  error,
  starting,
  measuring,
}

export interface iMeasurementButton {
  timeLeft: number;
  onStart: MouseEventHandler<any>;
  darkMode: boolean;
  torchOn: boolean;
  toggleFlash: Function;
  showCameraSwap?: boolean;
  fillCameraIcon?: boolean;
  swapCameras: Function;
  processing?: boolean;
  theme?: MeasurementButtonTheme;
  progress?: number;
  loading?: boolean;
  disabled?: boolean;
}

export const MeasurementButton = ({
  progress = 0,
  onStart,
  timeLeft,
  darkMode = true,
  torchOn = false,
  toggleFlash,
  showCameraSwap = true,
  fillCameraIcon = false,
  swapCameras,
  processing = false,
  theme = MeasurementButtonTheme.default,
  loading = false,
  disabled = false,
}: iMeasurementButton) => {
  const $theme = useTheme();

  const __toMMss = (milliseconds: number) => {
    var minutes = Math.floor(milliseconds / 1000 / 60);
    var seconds = Math.round((milliseconds / 1000) % 60);

    var minutesString = String(minutes).padStart(2, "0");
    var secondsString = String(seconds).padStart(2, "0");
    return minutesString + ":" + secondsString;
  };

  const __buttonAccentColor = (type: string) => {
    switch (theme) {
      case MeasurementButtonTheme.measuring:
        return $theme.value.functional_tertiary_500;
      case MeasurementButtonTheme.starting:
        return type == "ring" ? $theme.value.support_100 : $theme.value.functional_tertiary_500;
      case MeasurementButtonTheme.error:
        return $theme.value.functional_primary_500;
      default:
        return type === "ring" ? $theme.value.support_100 : $theme.value.brand_primary;
    }
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div
        className={cx(
          "text-center flex flex-row p-3 measurement-button-wrapper rounded-[20px] justify-center items-center shadow-md w-full sm:w-[321px]",
          darkMode ? "bg-brand-secondary-64 dark:bg-brand-tertiary-64" : "bg-white/[.64]",
        )}
      >
        <div className="measurement-timer justify-center">
          <span
            className={cx({
              active: [MeasurementButtonTheme.starting, MeasurementButtonTheme.measuring].includes(theme),
              error: theme === MeasurementButtonTheme.error,
              "dark-mode": $theme.value.dark_mode,
            })}
          >
            {__toMMss(timeLeft)}
          </span>
        </div>

        <div
          className={cx("measurement-btn btn-arc justify-center relative", { "opacity-50": disabled })}
          onClick={(evt) => {
            if (!disabled) {
              onStart(evt);
            }
          }}
        >
          <ButtonArc
            progressSize={3}
            size={58}
            progress={theme != MeasurementButtonTheme.error ? progress : 100}
            progressColor={__buttonAccentColor("ring")}
            content={
              <div
                className={cx("transition-all", processing ? "rounded-[25%] w-[23px] h-[23px]" : "rounded-[50%] w-[46px] h-[46px]")}
                style={{ backgroundColor: __buttonAccentColor("body") }}
              ></div>
            }
            noShadow
            track
            trackColor={!$theme.value.dark_mode ? $theme.value.brand_tertiary : $theme.value.brand_secondary}
            className={cx("cursor-pointer", { "cursor-not-allowed": disabled })}
            loading={loading}
            darkMode={darkMode}
          />
        </div>

        <div className="items-center flex justify-evenly">
          {darkMode && !torchOn ? (
            <IconFlash onClick={() => toggleFlash()} className="cursor-pointer" />
          ) : (
            <IconFlashOn onClick={() => toggleFlash()} className="cursor-pointer" />
          )}

          {showCameraSwap && (
            <IconSwapCamera
              onClick={() => swapCameras()}
              className="cursor-pointer"
              filled={fillCameraIcon}
              darkMode={!!(toInteger(darkMode ?? true) ^ toInteger($theme.value.dark_mode ?? false))}
            />
          )}
        </div>
      </div>
    </div>
  );
};
