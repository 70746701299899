import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BucketFeedbackResponse, BucketsResponse } from "intelliprove-streaming-sdk";

type MeasurementState = {
  uuid?: string
  feedback?: BucketFeedbackResponse;
  buckets?: BucketsResponse;
};

const initialState: MeasurementState = {
  uuid: undefined,
  feedback: undefined,
  buckets: undefined
};

export const globalErrorSlice = createSlice({
  name: "measurement",
  initialState,
  reducers: {
    setMeasurement: (state, action: PayloadAction<MeasurementState>) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setFeedback: (state, action: PayloadAction<BucketFeedbackResponse>) => {
      state.feedback = action.payload
    },
    setBucket: (state, action: PayloadAction<BucketsResponse>) => {
      state.buckets = action.payload
    },
    resetMeasurement: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMeasurement, resetMeasurement, setBucket, setFeedback, } = globalErrorSlice.actions;

export default globalErrorSlice.reducer;
