import { useTheme } from "@/context/theme";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ResultBiomarkerSkeletonCard = () => {
  const theme = useTheme();

  return (
    <SkeletonTheme baseColor={theme.value.dark_mode ? "#1A1A1A" : "#F7F9FD"} highlightColor="#fff">
      <div className="measurement-result-line flex flex-row sm:mb-5 items-center self-center">
        <div className="hidden sm:block mr-5">
          <Skeleton circle width={64} height={64} />
        </div>
        <div className="block sm:hidden mr-5">
          <Skeleton circle width={40} height={40} />
        </div>
        <div className="flex items-center justify-center relative">
          <Skeleton containerClassName="flex justify-center items-center w-[50px] h-[50px]" />
        </div>
      </div>
      <div className="h-[40px]">
        <div className="relative h-[12px] flex items-center">
          <Skeleton containerClassName="w-full" height={4} />
        </div>

        <div className="flex flex-row justify-between mt-2">
          <Skeleton count={2} width={15} height={15} inline containerClassName="flex w-full flex-row items-between justify-between" />
        </div>
      </div>
    </SkeletonTheme>
  );
};
