import { hexToRgb } from "@/utils/helper";
import { FC, useState, useContext, useCallback, createContext } from "react";

export interface ITheme {
  logo_url?: string;
  brand_primary?: string;
  brand_secondary?: string;
  brand_tertiary?: string;
  support_500?: string;
  support_300?: string;
  support_100?: string;
  functional_primary_500?: string;
  functional_primary_100?: string;
  functional_tertiary_500?: string;
  functional_tertiary_100?: string;
  functional_secondary_500?: string;
  functional_secondary_100?: string;
  font?: string;
  dark_mode?: boolean;
}

export interface IThemes {
  [key: string]: ITheme;
}

export interface IMappedTheme {
  [key: string]: string;
}

export const defaultTheme: ITheme = {
  // Proposed new structure
  brand_primary: "#3A79FF",
  brand_secondary: "#10183A",
  brand_tertiary: "#FFFFFF",
  support_500: "#092187",
  support_300: "#DBE1F6",
  support_100: "#F7F9FD",
  functional_primary_500: "#FF3A5D",
  functional_primary_100: "#FFEBEF",
  functional_secondary_500: "#F5B71E",
  functional_secondary_100: "#FEF8E8",
  functional_tertiary_500: "#62E38E",
  functional_tertiary_100: "#EDFCF2",
  font: "Poppins",
  dark_mode: false,
};

const generateFontUrl = (font: string) => {
  const fontWeights = ["400", "500", "600", "700", "800"];
  const fontUrl = `https://fonts.googleapis.com/css?family=${font}:${fontWeights.join(",")}`;
  return fontUrl;
};

const useValue = () => {
  const [value, setValue] = useState<ITheme>(defaultTheme);

  const applyTheme = useCallback(
    async (customTheme: Partial<ITheme>) => {
      const merged = {
        ...defaultTheme,
        ...customTheme,
      };

      setValue(merged);

      // Apply font
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = generateFontUrl(merged.font || "");
      document.head.appendChild(link);

      // Apply html styling
      const themeObject: IMappedTheme = mapTheme(merged);

      Object.keys(themeObject).forEach((property) => {
        if (property === "name") {
          return;
        }

        document.documentElement.style.setProperty(property, themeObject[property]);
      });
    },
    [value],
  );

  const mapTheme = (variables: ITheme): IMappedTheme => {
    const main = Object.keys(variables).reduce<any>((tmp, d: string) => {
      const key = `--color-${d.replaceAll("_", "-")}`;
      tmp[key] = variables[d as keyof ITheme] ?? "";
      return tmp;
    }, {});

    const extras = Object.keys(variables).reduce<any>((tmp, d: string) => {
      const key = `--rgb-${d.replaceAll("_", "-")}`;
      const value = variables[d as keyof ITheme];

      if (typeof value == "string" && value && hexToRgb(value)) {
        tmp[key] = hexToRgb(value);
      }
      return tmp;
    }, {});

    return {
      ...main,
      ...extras,
    };
  };

  return {
    value,
    applyTheme,
  };
};

const ThemeContext = createContext({} as ReturnType<typeof useValue>);
const useTheme = () => useContext(ThemeContext);
const ThemeProvider: FC<any> = (props) => {
  return <ThemeContext.Provider value={useValue()} {...props} />;
};

export { ThemeProvider, useTheme };
