import { JsonObject } from "../interfaces";

export default class PluginTranslations {
  locale: string;
  keys: JsonObject;

  constructor(locale: string, data: JsonObject) {
    this.locale = locale;
    this.keys = data;
  }
}
