import { useTheme } from "@/context/theme";
import { SVGProps } from "react";

export const IconCircleExclamation = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1751_3447)">
        <path
          d="M44 5.5C54.2108 5.5 64.0035 9.55624 71.2236 16.7764C78.4438 23.9965 82.5 33.7892 82.5 44C82.5 54.2108 78.4438 64.0035 71.2236 71.2236C64.0035 78.4438 54.2108 82.5 44 82.5C33.7892 82.5 23.9965 78.4438 16.7764 71.2236C9.55624 64.0035 5.5 54.2108 5.5 44C5.5 33.7892 9.55624 23.9965 16.7764 16.7764C23.9965 9.55624 33.7892 5.5 44 5.5ZM44 88C55.6695 88 66.8611 83.3643 75.1127 75.1127C83.3643 66.8611 88 55.6695 88 44C88 32.3305 83.3643 21.1389 75.1127 12.8873C66.8611 4.6357 55.6695 0 44 0C32.3305 0 21.1389 4.6357 12.8873 12.8873C4.6357 21.1389 0 32.3305 0 44C0 55.6695 4.6357 66.8611 12.8873 75.1127C21.1389 83.3643 32.3305 88 44 88ZM44 22C42.4875 22 41.25 23.2375 41.25 24.75V46.75C41.25 48.2625 42.4875 49.5 44 49.5C45.5125 49.5 46.75 48.2625 46.75 46.75V24.75C46.75 23.2375 45.5125 22 44 22ZM48.125 60.5C48.125 59.406 47.6904 58.3568 46.9168 57.5832C46.1432 56.8096 45.094 56.375 44 56.375C42.906 56.375 41.8568 56.8096 41.0832 57.5832C40.3096 58.3568 39.875 59.406 39.875 60.5C39.875 61.594 40.3096 62.6432 41.0832 63.4168C41.8568 64.1904 42.906 64.625 44 64.625C45.094 64.625 46.1432 64.1904 46.9168 63.4168C47.6904 62.6432 48.125 61.594 48.125 60.5Z"
          fill={theme.value.support_500}
        />
      </g>
      <defs>
        <clipPath id="clip0_1751_3447">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
