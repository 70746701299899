import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { iChartValue } from "./Interfaces";
import cx from "classnames";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTheme } from "@/context/theme";

ChartJS.register(...registerables);
ChartJS.register(ChartDataLabels);

export interface iLineChart {
  className?: string;
  title: string;
  font: string;
  colors: {
    lineColor: string;
    pointColor: string;
    pointBorderColor: string;
    gridColor: string;
    textColor: string;
    borderColor: string;
    ticksColor: string;
  };
  styling?: {
    pointRadius?: number;
    lineWidth?: number;
    tension?: number;
    grace?: number;
    tooltipBorderWidth?: number;
  };
  tooltips?: boolean;
  values?: Array<iChartValue>;
}

export const LineChart = ({ className, title, font, colors, styling = {}, tooltips = false, values = [] }: iLineChart) => {
  // Extract labels and data from values prop
  const labels = values.map((v) => v.label);
  const data = values.map((v) => v.value);
  const theme = useTheme();

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: data,
        borderColor: colors.lineColor,
        backgroundColor: colors.pointColor,
        pointBorderColor: colors.pointBorderColor,
        pointBackgroundColor: colors.pointColor,
        pointBorderWidth: 2,
        fill: false,
        tension: styling.tension ?? 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: tooltips,
        backgroundColor: theme.value.brand_tertiary,
        bodyColor: colors.textColor,
        borderColor: theme.value.support_300,
        borderWidth: styling.tooltipBorderWidth ?? 2,
        displayColors: false,
        footerColor: "#000",
        callbacks: {
          label: function (context: any) {
            const itemIdx = context.dataIndex;
            const item = values[itemIdx];
            return item.hint;
          },
          title: function (context: any) {
            return "";
          },
        },
      },
      title: {
        display: true,
        text: title,
        color: colors.textColor,
        font: {
          family: font,
          size: 16,
          weight: 500,
        },
      },
      datalabels: {
        color: colors.textColor,
        clamp: true,
        align: (context: any) => {
          let data: Array<number> = context.dataset.data;
          let sum = 0;
          if (context.dataIndex < data.length - 1) {
            sum += data[context.dataIndex] - data[context.dataIndex + 1];
          }

          if (context.dataIndex >= 1) {
            sum += data[context.dataIndex] - data[context.dataIndex - 1];
          }

          return sum >= 0 ? "end" : "start";
        },
        offset: 5,
      },
    },
    layout: {
      padding: 5,
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        ticks: {
          color: colors.ticksColor,
          border: {
            display: false,
          },
        },
        grid: {
          color: colors.gridColor,
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grace: styling.grace ?? 7,
        ticks: {
          color: colors.ticksColor,
          display: false,
        },
        grid: {
          color: colors.gridColor,
          borderColor: colors.gridColor,
        },
      },
    },
    elements: {
      point: {
        radius: styling.pointRadius ?? 3,
      },
      line: {
        borderWidth: styling.lineWidth ?? 2,
      },
    },
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "vertical",
          scaleID: "x-axis-0",
          value: "Label 1",
          borderColor: "red",
          borderWidth: 2,
          label: {
            content: "Value: 10",
            enabled: true,
          },
        },
      ],
    },
  };

  return (
    <div
      className={cx(
        className,
        "py-2 px-4 rounded-[20px] w-full h-full min-h-[220px]",
        `border-[1px] border-[${colors.borderColor}] shadow-md bg-brand-tertiary `,
      )}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};
