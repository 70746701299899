import "./CameraSelector.scss";
import cx from "classnames";
import { useTheme } from "@/context/theme";

export interface iCameraSelector {
  darkMode?: boolean;
  devices: MediaDeviceInfo[];
  selectedDevice: number;
  deviceSelected: (device: MediaDeviceInfo) => void;
}

export const CameraSelector = ({ darkMode = false, devices, selectedDevice, deviceSelected }: iCameraSelector) => {
  const theme = useTheme();

  return (
    <div
      className={cx("bg-white/[.64] text-black w-full sm:w-[321px] rounded-[20px] dark:bg-brand-primary-64 dark:text-brand-secondary", {
        "bg-brand-primary-64 text-white": darkMode,
      })}
    >
      <ul className="flex flex-col">
        {devices.map((d, i) => (
          <li
            key={d.deviceId}
            className={cx(
              "px-4 py-3 rounded-[20px] cursor-pointer flex gap-4",
              { "hover:bg-gray-300 focus:bg-gray-300": !darkMode },
              { "hover:bg-brand-secondary focus:bg-brand-secondary dark:hover:bg-brand-primary dark:focus:bg-brand-primary": darkMode },
            )}
            onClick={() => deviceSelected(d)}
          >
            <span
              className={cx("h-8 w-10 flex justify-center items-center text-center rounded-[8px] font-normal text-brand-tertiary", {
                "bg-brand-primary dark:text-white dark:bg-support-100": i === selectedDevice,
                "bg-brand-secondary dark:text-white dark:bg-brand-primary": i !== selectedDevice,
              })}
            >
              {i + 1}
            </span>

            <span className="border-b flex-grow mr-4 border-b-white border-opacity-30">
              <p className={cx("px-2", { "font-bold": i === selectedDevice })}>{d.label}</p>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
