import { useTheme } from "@/context/theme";
import { MouseEventHandler } from "react";

export const IconFlash = ({ ...props }: { className?: string; onClick: MouseEventHandler }) => {
  const theme = useTheme();

  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.5281 2.86577C20.7797 2.64155 21.1023 2.52124 21.4359 2.52124C22.3766 2.52124 23.0328 3.4564 22.7156 4.34233L18.9914 14.7712H25.0234C25.8383 14.7712 26.5 15.433 26.5 16.2478C26.5 16.6744 26.3195 17.0736 26.0023 17.358L11.4664 30.1822C11.2203 30.4009 10.8977 30.5212 10.5641 30.5212C9.62344 30.5212 8.96719 29.5861 9.27891 28.7001L13.0086 18.2712H6.91094C6.13437 18.2712 5.5 17.6369 5.5 16.8603C5.5 16.4556 5.675 16.0728 5.97578 15.8048L20.5281 2.86577ZM20.5555 5.18452L7.80234 16.5212H14.25C14.5344 16.5212 14.8023 16.658 14.9664 16.8931C15.1305 17.1283 15.1688 17.4236 15.0758 17.6916L11.4391 27.8744L24.3016 16.5212H17.75C17.4656 16.5212 17.1977 16.3845 17.0336 16.1494C16.8695 15.9142 16.8312 15.6189 16.9242 15.3509L20.5555 5.17905V5.18452Z"
        fill={theme.value.dark_mode ? theme.value.brand_secondary : theme.value.brand_tertiary}
      />
    </svg>
  );
};
