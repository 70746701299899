import { useSearchParams, Outlet, useLocation, useNavigate } from "react-router-dom";
import { shallowEqual } from "react-redux";

import "@/translations/i18next";

import { useAppDispatch, useAppSelector } from "./hooks";
import React, { useEffect, useState } from "react";
import {
  setAuth,
  setDuration,
  setLanguage,
  setMetadata,
  setOnboarding,
  setPluginSetting,
  setQuestionnaireQuestions,
} from "./store/reducers/setting.reducer";
import { ModalProvider } from "./utils/modal.provider";
import { AppModal, iAppModal } from "./components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { IntelliProveService } from "./utils/intelliprove.service";
import { useTheme } from "./context/theme";
import { Cookie, useLanguage } from "./context/language";
import { ScreenSizeTooSmallModal } from "./components/Modal/ModalScreenSize";
import { customerFromJwt, debugLog } from "./utils/helper";
import { AuthenticationMethod, IntelliProveAPIError, PluginSettings, QuestionItem } from "intelliprove-streaming-sdk";
import { useCookies } from "react-cookie";
import { IconCircleX } from "./components/Icons/icon_circle_x";
import { IconCircleKey } from "./components/Icons/icon_circle_key";
import { monitor } from "./utils/monitoring.service";
import { parseInt } from "lodash";

export interface iAppContext {
  sdk: IntelliProveService | null;
}
export const AppContext = React.createContext<iAppContext>({
  sdk: null,
});

export const AppBootstrap = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { changeLanguage } = useLanguage();
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies([Cookie.Language]);
  const [error, setError] = useState<iAppModal | undefined>(undefined);

  const [appState, setAppState] = useState<iAppContext>({
    sdk: null,
  });

  const __getSearchParam = (key: string) => {
    if (searchParams.get(key)) {
      let val = searchParams.get(key);
      val?.replaceAll(" ", "+");
      return val;
    }

    // attempt to get via location API
    const urlSearchParams = new URLSearchParams(window.location.search);
    let val = urlSearchParams.get(key);
    if (val) {
      val = val.replace(" ", "+");
    }
    return val;
  };

  const __handleNoAuth = () => {
    theme.applyTheme({});

    // if (location.pathname !== "/") {
    //   return navigate("/", { replace: true });
    // }

    setError({
      isOpen: true,
      title: t("access_not_granted_title", "Access not granted"),
      text: t(
        "access_not_granted",
        "To use the health check, you must have the access key. If you don't have it, please get in touch with your responsible team to request access.",
      ),
      dismissable: false,
      icon: <IconCircleX />,
    });
  };

  const __useDefaultTheming = () => {
    const defaultTheming = __getSearchParam("default");
    if (defaultTheming) {
      try {
        const value = JSON.parse(defaultTheming);
        if (value) {
          return true;
        }
      } catch (e) {
        monitor.trackError(e);
        debugLog(`defaultTheming was truthy but JSON.parse was failed. Value -> ${defaultTheming}`);
      }
    }

    return false;
  };

  /**
   * Only accept object
   * https://app.clickup.com/t/86bxnx995 seems caused by (empty?) string on theming property
   * @param theming
   * @returns object | null
   */
  const __genThemeObject = (theming: any): object | null => {
    return typeof theming === "object" ? theming : null;
  };

  const __createIntelliProveService = async (authToken: string, authenticationMethod: AuthenticationMethod): Promise<boolean> => {
    if (appState.sdk) return true;

    const sdk = new IntelliProveService(authToken, authenticationMethod);
    try {
      await sdk.instance?.getCurrentUser();
      setAppState({ sdk: sdk });
      return true;
    } catch (err) {
      return false;
    }
  };

  const __handleSettingsLoad = (settings: PluginSettings) => {
    const isDefaultTheme = __useDefaultTheming();

    const mode = __getSearchParam("mode");
    const skipWelcome = __getSearchParam("no_welcome");
    const skipResults = __getSearchParam("no_results");

    const theming = isDefaultTheme ? {} : __genThemeObject(settings.theming) ?? {};

    settings.skip_welcome = skipWelcome != null ? ["1", "true"].includes(skipWelcome) : settings.skip_welcome;
    settings.skip_results = skipResults != null ? ["1", "true"].includes(skipResults) : settings.skip_results;
    settings.embedded_iframe = mode != null ? mode === "iframe" : settings.embedded_iframe;
    settings.embedded_app = mode != null ? mode === "app" : settings.embedded_app;
    settings.theming = theming;

    if (settings.theming["dark_mode"] === true) {
      document.body.classList.add("dark");
    }

    theme.applyTheme(settings.theming);
    dispatch(setPluginSetting(settings));
  };

  const __loadQuestionDefinitions = async () => {
    if (appState.sdk === null) {
      throw new ReferenceError("SDK reference in App state is not set correctly!");
    }
    const sdk = appState.sdk;

    const questionIdsJoined = __getSearchParam("q_ids");

    let questionDefs: QuestionItem[] = [];
    const questionIds = (questionIdsJoined ? questionIdsJoined.split(",") : []).map((id) => parseInt(id));
    if (sdk.instance && questionIds.length > 0) {
      questionDefs = await sdk.instance.getQuestions(questionIds);
    }

    dispatch(setQuestionnaireQuestions(questionDefs));
  };

  // On app state change
  useEffect(() => {
    if (appState.sdk === null) {
      return;
    }

    const sdk = appState.sdk;

    // plugin settings
    sdk.instance
      ?.getPluginSettings()
      .then((settings) => {
        if (!settings) {
          console.warn("getPluginSettings returning undefined?");
          return;
        }

        debugLog("Plugin settings loaded");
        __handleSettingsLoad(settings);
      })
      .catch((err) => {
        console.error("Failed to get plugin settings");
        throw err;
      });

    __loadQuestionDefinitions()
      .then(() => {
        debugLog("Questions loaded");
      })
      .catch((err) => {
        console.error("Failed to load question definitions!");
        throw err;
      });

    // language
    const language = __getSearchParam("language") ?? cookies.language ?? "en";
    changeLanguage(sdk, language);
    dispatch(setLanguage(language));
  }, [appState]);

  useEffect(() => {
    if (setting.pluginSettings && location.pathname === "/") {
      if (setting.pluginSettings.skip_welcome) {
        navigate(setting.onboarding ? "/measurement/onboarding" : "/measurement/do", { replace: true, state: { from: location.pathname } });
      } else {
        navigate("/welcome", { replace: true, state: { from: location.pathname } });
      }
    }
  }, [setting]);

  // On AppBootstrap init
  useEffect(() => {
    (async () => {
      if (!setting.actionToken) {
        const authToken = __getSearchParam("action_token") ?? __getSearchParam("auth0");

        if (!authToken) {
          __handleNoAuth();
          return;
        }

        monitor.setCustomer(customerFromJwt(authToken));
        monitor.trackView("/");

        try {
          const patient = __getSearchParam("patient");
          const performer = __getSearchParam("performer");
          const duration = __getSearchParam("duration");
          const authenticationMethod = !__getSearchParam("auth0") ? AuthenticationMethod.ActionToken : AuthenticationMethod.Auth0;

          // debugLog(`AppBootstrap: removing all search params from url`);
          // window.history.replaceState({}, document.title, window.location.pathname);

          debugLog(`AppBootstrap: got action_token, checking token validity`);
          const authenticated = await __createIntelliProveService(authToken, authenticationMethod);
          if (!authenticated) {
            __handleNoAuth();
            return;
          }
          dispatch(setAuth([authToken, authenticationMethod]));

          const onboarding = !Boolean(localStorage.getItem("onboarding"));
          dispatch(setOnboarding(onboarding));

          const userId = monitor.metadata.userId;
          const sessionId = monitor.metadata.sessionId;

          dispatch(setMetadata({ patient, performer, userId, sessionId }));
          dispatch(setDuration(!duration || isNaN(Number(duration)) ? 20000 : Number(duration) * 1000));

          monitor.trackDeviceInfo();
        } catch (e) {
          monitor.trackError(e);
          debugLog(`ERROR:`, e);
          theme.applyTheme({});
          setError({
            isOpen: true,
            title: t("access_not_granted_title", "Access not granted"),
            text: t("access_not_granted_invalid_expired_key"),
            dismissable: false,
            icon: <IconCircleKey />,
          });
          return;
        }
      }
    })();
  }, []);

  // useEffect(() => {
  //   const defaultTheming = __getSearchParam("default");
  //   if (!defaultTheming) return;
  //
  //   try {
  //     const value = JSON.parse(defaultTheming);
  //     if (value) {
  //       theme.applyTheme({});
  //     }
  //   } catch (e) {
  //     monitor.trackError(e);
  //     debugLog(`fail: ${defaultTheming}`);
  //   }
  // }, [location.search]);
  //
  return (
    <AppContext.Provider value={appState}>
      <ModalProvider>
        {/* Router page content */}
        {appState.sdk && <Outlet />}

        <ScreenSizeTooSmallModal />

        <AppModal
          isOpen={error?.isOpen}
          title={error?.title}
          text={error?.text}
          dismissText={t("try_again")}
          onDismiss={() => error?.onDismiss?.()}
          dismissable={error?.dismissable}
          icon={error?.icon}
        />
      </ModalProvider>
    </AppContext.Provider>
  );
};
