import { useTheme } from "@/context/theme";
import { iIconOnboarding } from "./type";
import cx from "classnames";

export const IconOnboarding1 = ({ className, ...props }: iIconOnboarding) => {
  const theme = useTheme();

  return (
    <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} className={cx(className)}>
      <path
        d="M104.97 103.525C114.665 103.525 122.525 95.5015 122.525 85.6044C122.525 75.7074 114.665 67.6836 104.97 67.6836C95.2751 67.6836 87.415 75.7074 87.415 85.6044C87.415 95.5015 95.2751 103.525 104.97 103.525ZM104.97 69.9237C113.454 69.9237 120.331 76.9436 120.331 85.6044C120.331 94.2653 113.454 101.285 104.97 101.285C96.4861 101.285 89.6094 94.2653 89.6094 85.6044C89.6094 76.9436 96.4861 69.9237 104.97 69.9237Z"
        fill={theme.value.support_500}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.7072 118.041L86.6813 118.058C86.2066 118.374 85.5653 118.246 85.2488 117.772C84.9323 117.298 85.0606 116.657 85.5353 116.341C87.4314 115.079 92.9402 111.452 104.997 111.452C117.054 111.452 122.563 115.079 124.459 116.341C124.934 116.657 125.062 117.298 124.746 117.772C124.429 118.246 123.788 118.374 123.313 118.058L123.287 118.041C121.614 116.927 116.491 113.515 104.997 113.515C93.5031 113.515 88.3801 116.927 86.7072 118.041Z"
        fill={theme.value.support_500}
      />
      <path
        d="M86.1097 126.633H83.0264C80.8172 126.633 79.0264 124.842 79.0264 122.633V119.559"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M123.886 126.633H126.97C129.179 126.633 130.97 124.842 130.97 122.633V119.559"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M86.1097 60.6123H83.0264C80.8172 60.6123 79.0264 62.4032 79.0264 64.6123V67.686"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M123.886 60.6123H126.97C129.179 60.6123 130.97 62.4032 130.97 64.6123V67.686"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <rect x="77.8457" y="136.062" width="54.3056" height="15.3263" rx="4" fill={theme.value.support_300} />
      <ellipse cx="104.999" cy="143.725" rx="4.72222" ry="4.71579" fill="white" />
      <ellipse cx="104.998" cy="143.725" rx="5.90278" ry="5.89474" stroke="white" />
      <rect x="27.7217" y="53" width="154.556" height="106" rx="9" stroke={theme.value.support_500} strokeWidth="4" />
      <path
        d="M20 154.925H190V154.925C190 158.831 186.833 161.998 182.926 161.998H27.0737C23.167 161.998 20 158.831 20 154.925V154.925Z"
        fill={theme.value.support_500}
      />
      <rect x="93.1943" y="157.281" width="23.6111" height="2.35789" rx="1.17895" fill="white" />
    </svg>
  );
};
