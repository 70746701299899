import { AppContext } from "@/AppBootstrap";
import { AnswerCallback, DiscardedCallback } from "@/components/QuestionModal/QuestionModal";
import { PossibleQuestionAnswer, QuestionItem } from "intelliprove-streaming-sdk";
import { useCallback, useContext, useState } from "react";

export interface iUseQuestionnaire {
  questions: QuestionItem[];
}

export interface UserQuestionAnswer {
  [question_id: number]: PossibleQuestionAnswer;
}

export interface UserQuestionProgress {
  completed: number[];
  discarded: number[];
  expire: number;
}

const addTimeToUnix = (hours: number = 0, days: number = 0, weeks: number = 0) => {
  const oneHour = 1000 * 60 * 60;
  const h = oneHour * hours;
  const d = oneHour * 24 * days;
  const w = oneHour * 24 * 7 * weeks;
  return h + d + w;
};

export const useQuestionnaire = ({ questions }: iUseQuestionnaire) => {
  const appState = useContext(AppContext);
  const [activeQuestion, setActiveQuestion] = useState<number>(-1);
  let questionProgress: UserQuestionProgress = {
    completed: [],
    discarded: [],
    expire: Date.now() + addTimeToUnix(0, 0, 2),
  };

  const _addCompletedQuestion = useCallback((q_id: number) => {
    questionProgress.completed.push(q_id);
    setActiveQuestion(-1);
  }, []);

  const _addDiscardedQuestion = useCallback((q_id: number) => {
    questionProgress.discarded.push(q_id);
    setActiveQuestion(-1);
  }, []);

  const _canAskQuestion = useCallback((q_id: number) => {
    return !(questionProgress.discarded.includes(q_id) || questionProgress.completed.includes(q_id));
  }, []);

  const questionAnswerCallback: AnswerCallback = (question_id: number, answer: PossibleQuestionAnswer) => {
    const sdk = appState.sdk;
    const measurement_id = sdk?.instance?.status?.uuid;
    if (typeof measurement_id !== "string") {
      console.error("cannot save answer to question, measurement uuid is not yet set!");
      return;
    }
    sdk?.instance?.answerQuestion(question_id, measurement_id, answer);
    _addCompletedQuestion(question_id);
  };

  const questionDiscardCallback: DiscardedCallback = (question_id: number) => {
    _addDiscardedQuestion(question_id);
  };

  const nextQuestion = useCallback(() => {
    for (let i = 0; i < questions.length; i++) {
      const q = questions[i];
      if (_canAskQuestion(q.id)) {
        setActiveQuestion(q.id);
        return;
      }
    }

    // No questions left
    setActiveQuestion(-1);
  }, [questions]);

  const getQuestion = useCallback(() => {
    if (activeQuestion === -1) {
      return null;
    }

    const found = questions.filter((q) => q.id === activeQuestion);
    if (found.length === 0) return null;
    return found[0];
  }, [activeQuestion, questions]);

  return {
    activeQuestion,
    questionAnswerCallback,
    questionDiscardCallback,
    nextQuestion,
    getQuestion,
  };
};
