import { useTheme } from "@/context/theme";
import { SVGProps } from "react";

export const IconAnsBalance = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8764 59.978C12.6236 59.978 12.3708 59.8694 12.1902 59.6521L7.36852 53.9131C5.68906 51.8311 4.76806 49.2241 4.75 46.5447V34.0528C4.75 31.5544 6.77258 29.5267 9.26469 29.5267C11.7568 29.5267 13.7794 31.5544 13.7794 34.0528V43.3946C13.7794 43.9015 13.3821 44.2998 12.8764 44.2998C12.3708 44.2998 11.9735 43.9015 11.9735 43.3946V34.0528C11.9735 32.5501 10.7636 31.3372 9.26469 31.3372C7.76581 31.3372 6.55587 32.5501 6.55587 34.0528V46.5266C6.55587 48.7715 7.35046 50.9802 8.75904 52.7363L13.5627 58.4754C13.8877 58.8556 13.8336 59.4349 13.4543 59.7427C13.2918 59.8875 13.0751 59.9599 12.8764 59.9599V59.978Z"
        fill={theme.value.support_500}
      />
      <path
        d="M23.7115 59.978C23.2059 59.978 22.8086 59.5797 22.8086 59.0728V51.8311C22.8086 49.8577 22.2307 47.9387 21.1291 46.3093L18.3842 42.0729C17.6618 40.8599 16.1449 40.4798 14.9711 41.1858C13.8153 41.9643 13.4722 43.3402 14.014 44.4446L17.2284 49.8034C17.4813 50.2379 17.3549 50.7811 16.9214 51.0526C16.488 51.3061 15.9463 51.1612 15.6754 50.7448L12.4248 45.3136C11.4496 43.3402 12.0636 40.9867 13.8153 39.7737L14.0501 39.6289C16.0907 38.4159 18.7273 39.0857 19.9192 41.1134L22.6461 45.3136C23.9463 47.2507 24.6325 49.5138 24.6145 51.8492V59.0909C24.6145 59.5978 24.2172 59.9961 23.7115 59.9961V59.978Z"
        fill={theme.value.support_500}
      />
      <path
        d="M52.6055 59.978C52.4069 59.978 52.1902 59.9056 52.0276 59.7608C51.6484 59.4349 51.5942 58.8737 51.9193 58.4935L56.741 52.7545C58.1495 51.0164 58.9261 48.8077 58.9441 46.5447V34.0709C58.9441 32.5683 57.7342 31.3553 56.2353 31.3553C54.7364 31.3553 53.5265 32.5683 53.5265 34.0709V43.4127C53.5265 43.9196 53.1292 44.3179 52.6236 44.3179C52.1179 44.3179 51.7206 43.9196 51.7206 43.4127V34.0709C51.7206 31.5725 53.7432 29.5449 56.2353 29.5449C58.7274 29.5449 60.75 31.5725 60.75 34.0709V46.5447C60.75 49.206 59.8109 51.8311 58.1495 53.895L53.3279 59.6522C53.1473 59.8694 52.8944 59.978 52.6416 59.978H52.6055Z"
        fill={theme.value.support_500}
      />
      <path
        d="M41.7704 59.9778C41.2648 59.9778 40.8675 59.5795 40.8675 59.0726V51.8309C40.8675 49.4955 41.5537 47.2324 42.854 45.2953L45.5989 41.077C46.7908 39.0674 49.4273 38.3976 51.4499 39.6106L51.6666 39.7554C53.4364 40.9503 54.0684 43.3038 53.0933 45.2229L49.8066 50.7084C49.5538 51.1429 48.9939 51.2697 48.5605 51.0162C48.1271 50.7627 48.0007 50.2015 48.2535 49.767L51.5041 44.3358C52.0097 43.3038 51.6486 41.9098 50.6373 41.2399C49.319 40.4615 47.8021 40.8416 47.0978 42.0184L44.3348 46.2729C43.2332 47.9204 42.6373 49.8394 42.6553 51.8128V59.0545C42.6553 59.5614 42.258 59.9597 41.7524 59.9597L41.7704 59.9778Z"
        fill={theme.value.support_500}
      />
      <path
        d="M50.1679 11.2592C49.1385 7.7289 46.3394 4.95896 43.0347 4.21668C39.6035 3.45631 35.9737 4.83223 32.7412 8.1634C29.5267 4.83223 25.8969 3.4382 22.4296 4.19858C19.1249 4.94085 16.3258 7.69269 15.2964 11.223C14.2671 14.7533 15.17 18.2474 17.7524 20.8545L32.1272 33.9076C32.3078 34.0705 32.5245 34.1429 32.7412 34.1429C32.9579 34.1429 33.1746 34.0705 33.3552 33.9076L47.7661 20.8182C50.3124 18.2655 51.1972 14.7714 50.1679 11.2411V11.2592ZM46.52 19.5147L32.7592 32.0247L19.2513 19.7501H23.9646C24.2536 19.7501 24.5245 19.6234 24.687 19.388L27.3416 15.8396L31.2965 22.7735C31.441 23.0451 31.7118 23.208 32.0188 23.2261C32.0369 23.2261 32.055 23.2261 32.073 23.2261C32.362 23.2261 32.6328 23.0994 32.7954 22.864L36.0098 18.5733H40.2356C40.7412 18.5733 41.1385 18.175 41.1385 17.6681C41.1385 17.1612 40.7412 16.7629 40.2356 16.7629H35.5584C35.2694 16.7629 34.9985 16.8896 34.836 17.125L32.1814 20.6734L28.2265 13.7395C28.082 13.4679 27.8112 13.305 27.5042 13.2869C27.1972 13.2869 26.9082 13.3955 26.7276 13.649L23.5132 17.9397H17.7885C16.705 16.1111 16.4161 13.9205 17.0662 11.7299C17.915 8.83326 20.1723 6.57023 22.8631 5.95469C23.3506 5.84606 23.8382 5.79175 24.3439 5.79175C26.9443 5.79175 29.6531 7.27629 32.1091 10.0643C32.4522 10.4626 33.1204 10.4626 33.4635 10.0643C36.3891 6.73317 39.6577 5.28483 42.6735 5.95469C45.3462 6.55213 47.6216 8.81515 48.4704 11.7299C49.3011 14.6085 48.5968 17.4328 46.5561 19.4785L46.52 19.5147Z"
        fill={theme.value.support_500}
      />
    </svg>
  );
};
