import { useTheme } from "@/context/theme";
import { MouseEventHandler } from "react";

export const IconDoubleArrow = ({ className, onClick, ...props }: { className?: string; onClick: MouseEventHandler<SVGSVGElement> }) => {
  const theme = useTheme();

  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} onClick={onClick}>
      <rect width="36" height="36" rx="18" fill={theme.value.dark_mode ? theme.value.brand_secondary : theme.value.brand_primary} />
      <path
        d="M17.7824 17.4688C18.0754 17.7617 18.0754 18.2373 17.7824 18.5301L13.2824 23.0286C12.9895 23.3215 12.5137 23.3215 12.2207 23.0286C11.9277 22.7358 11.9277 22.2601 12.2207 21.9673L16.191 17.9983L12.223 14.0293C11.9301 13.7364 11.9301 13.2608 12.223 12.9679C12.516 12.6751 12.9918 12.6751 13.2848 12.9679L17.7848 17.4664L17.7824 17.4688Z"
        fill={theme.value.brand_tertiary}
      />
      <path
        d="M23.7824 17.4688C24.0754 17.7617 24.0754 18.2373 23.7824 18.5301L19.2824 23.0286C18.9895 23.3215 18.5137 23.3215 18.2207 23.0286C17.9277 22.7358 17.9277 22.2601 18.2207 21.9673L22.191 17.9983L18.223 14.0293C17.9301 13.7364 17.9301 13.2608 18.223 12.9679C18.516 12.6751 18.9918 12.6751 19.2848 12.9679L23.7848 17.4664L23.7824 17.4688Z"
        fill={theme.value.brand_tertiary}
      />
    </svg>
  );
};
