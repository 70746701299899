import "./Header.scss";
import { useLocation } from "react-router-dom";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { BackButton } from "../BackButton/BackButton";
import { ReactComponent as HeaderLogo } from "@/images/header-logo.svg";
import { ButtonSwitchLanguage } from "../ButtonSwitchLanguage/ButtonSwitchLanguage";
import { useAppSelector } from "@/hooks";
import { shallowEqual } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@/context/theme";
import { MessagingStage, messageService } from "@/utils/messaging.service";
import { debugLog } from "@/utils/helper";

export interface iHeader {
  rightButton?: ReactNode;
  className?: string;
  onBack?: Function;
  loading?: boolean;
  hideBackButton?: boolean;
}

export const Header = ({ rightButton, className = "", onBack, loading, hideBackButton }: iHeader) => {
  const location = useLocation();
  const theme = useTheme();
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);

  const __logo = () => {
    if (theme.value.logo_url) {
      if (theme.value.logo_url?.startsWith("http")) {
        // return customer logo"
        return <img src={theme.value.logo_url} alt="" className="h-full max-h-[45px] object-contain px-3 sm:p-0" />;
      } else if (theme.value.logo_url === "default") {
        return <HeaderLogo className="h-10" />;
      }
    }

    // empty
    return <span />;
  };

  const __showBackButton = () => {
    if (hideBackButton === true) return false;

    const previousRoute = location.state?.from;
    debugLog(`Routing - From  ${previousRoute} To ${location.pathname}`);

    const canNavigateBack = previousRoute !== undefined && previousRoute != "/";

    if (setting.pluginSettings?.embedded_app) {
      // Always show back button, but when end of nav stack reached -> sent "dismiss" event
      if (!canNavigateBack) {
        // Initial route reached
        onBack = () => {
          messageService.dismiss();
        };
      }
      return true;
    } else if (setting.pluginSettings?.embedded_iframe) {
      // Never show back button
      return false;
    }

    return canNavigateBack || onBack;
  };

  if (loading) {
    return (
      <SkeletonTheme baseColor={theme.value.dark_mode ? "#1A1A1A" : "#F7F9FD"} highlightColor="#fff">
        <Skeleton containerClassName="self-center align-items-center w-1/4 py-5" height={45} />
      </SkeletonTheme>
    );
  }

  return (
    <div className={twMerge(`header-wrapper flex flex-row relative place-content-between py-5 align-items-center ${className}`)}>
      <div className="flex w-1/4">{__showBackButton() && <BackButton className="flex" onClick={onBack} />}</div>
      <div className="flex flex-grow items-center justify-center w-3/4">{__logo()}</div>

      <div className="flex w-1/4">
        <div className="flex">{rightButton}</div>
        {!setting.pluginSettings?.embedded && <ButtonSwitchLanguage />}
      </div>
    </div>
  );
};
