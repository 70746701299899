import { RETRIES, SDK_CONFIG } from "./statics";
import { wait } from "./utils/async";
import { JsonObject } from "./interfaces";

import MediaService from "./services/media_service";
import IntelliProveAPI, { AuthenticationMethod } from "./services/api_service";
import IntelliProveWebsocketService from "./services/websocket_service";

import ErrorType from "./exceptions/error_types";
import QualityError from "./exceptions/quality_exception";
import IntelliProveAPIError from "./exceptions/intelliprove_api_exception";
import IntelliProveSDKError from "./exceptions/intelliprove_sdk_expection";

import UserInfo from "./models/user_info";
import LiveError, { LiveErrorAction } from "./models/live_error";
import LiveResults from "./models/live_results";
import StreamStatus from "./models/stream_status";
import PluginSettings from "./models/plugin_settings";
import QualityResponse from "./models/quality_response";
import BucketsResponse from "./models/buckets_response";
import LiveQualityCheck from "./models/live_quality_check";
import StreamingMetadata from "./models/streaming_metadata";
import WebsocketSettings from "./models/websocket_settings";
import BiomarkersResponse from "./models/biomarkers_response";
import ResultsCountResponse from "./models/results_count_response";
import BucketFeedbackResponse from "./models/bucket_feedback_response";
import UnprocessableVideoResponse from "./models/unprocessable_video_response";
import PluginTranslations from "./models/plugin_translations";
import IntelliProveMediaError from "./exceptions/intelliprove_media_exception";
import FaceLandmarkService from "./services/face_landmark_service";
import { QuestionItem } from "./models/questions_response";
import { ChoosenAnswer, QuestionAnswerRequest } from "./models/question_answer_request";

export type StreamingDataCallback = (stream: StreamStatus, data: Blob) => void;
export type StreamingStopCallback = (stream: StreamStatus) => void;
export type StreamingFeedbackCallback = (data: LiveQualityCheck | LiveResults | LiveError) => void;

export type AttachOptions = {
  cameraId?: string | null;
  enableFaceLandmark?: boolean;
  flipFaceLandmarkHorizontal?: boolean;
};

export default class IntelliProveStreamingSDK {
  debugMode: boolean = false;

  api: IntelliProveAPI;
  media: MediaService | null = null;
  ws: IntelliProveWebsocketService | null = null;
  faceLandmark: FaceLandmarkService | null = null;

  selectedCameraIdx: number = 0;
  status: StreamStatus | null = null;

  private _timeStopMessage: ReturnType<typeof setTimeout> | null = null;
  private _resetOnTimestamp: number | null = null;
  private _attachOptions?: AttachOptions = null;

  stopCallback: StreamingStopCallback = (_) => {};
  dataCallback: StreamingDataCallback = (_) => {};
  feedbackCallback: StreamingFeedbackCallback = (_) => {};

  constructor(
    authentication: string,
    url: string = "https://engine.intelliprove.com",
    authenticationMethod: AuthenticationMethod = AuthenticationMethod.ActionToken,
  ) {
  
	const regex = /\/v[0-9]+\/$/;
	if (regex.test(url)) {
	  url = url.slice(0, -3); // cut off 'v#/' -> # == number
	}

    url = (url.charAt(url.length - 1) === "/" ? url.slice(0, -1) : url);
    this.api = new IntelliProveAPI(url, authentication, authenticationMethod);

    this.dataHandler = this.dataHandler.bind(this);
    this.messageHandler = this.messageHandler.bind(this);
    this.stopHandler = this.stopHandler.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
  }

  toggleDebugMode() {
    this.debugMode = !this.debugMode;
    if (this.media) {
      this.media.debugMode = !this.media.debugMode;
    }
  }

  private createMediaService(videoElementId: string) {
    const vidEl = document.getElementById(videoElementId) as HTMLVideoElement | null;

    if (this.debugMode) {
      console.log("DEBUG: Got video element:");
      console.log(vidEl);
    }
    if (vidEl === null) {
      throw new IntelliProveSDKError("Invalid video element id!", ErrorType.UI);
    }

    this.media = new MediaService(vidEl, (err: IntelliProveMediaError) => {
      if (err.fixable) {
        return;
      }

      const error = new LiveError({
        exc_type: "FrameRateError",
        info: "The minimum frame rate could not be maintained",
        exc_code: 1002,
        exc_action: LiveErrorAction.STOP,
      });
      this.feedbackCallback(error);
    });
    this.media.debugMode = this.debugMode;
  }

  private _handleError(err: Error, handleClosure: boolean = false) {
    if (this.debugMode) console.error(err);

    if (err instanceof TypeError && handleClosure) {
      if (this.debugMode) console.warn("Page closure: " + err);
      return;
    }

    if (err instanceof IntelliProveAPIError || err instanceof IntelliProveSDKError) {
      throw err;
    }

    throw new IntelliProveSDKError(`Something unexpected went wrong!\n${err}`, ErrorType.Connection);
  }

  private async _handleAction(action: LiveErrorAction, resetOn: number | null) {
    switch (action) {
      case LiveErrorAction.RESET:
        this.status.resetTime();
        if (resetOn !== null) {
          this._resetOnTimestamp = resetOn;
        }
        break;

      case LiveErrorAction.STOP:
        this._timeStopMessage = setTimeout(
          (async () => {
            await this.stop();
          }).bind(this),
          SDK_CONFIG.stopEventTime,
        );
        break;

      default:
        break;
    }
  }

  private async messageHandler(wsMessage: JsonObject) {
    if (!("type" in wsMessage)) {
      return;
    }

    let content: LiveQualityCheck | LiveResults | LiveError | null = null;

    switch (wsMessage["type"]) {
      case "feedback":
        const feedback = wsMessage["feedback"];
        content = new LiveQualityCheck(feedback);
        await this._handleAction(content.action, content.resetOn);
        break;

      case "values":
        const values = wsMessage["values"];
        content = new LiveResults(values);
        break;

      case "error":
        const error = wsMessage["error"];
        content = new LiveError(error);
        await this._handleAction(content.action, content.resetOn);
        break;

      default:
        break;
    }

    this.feedbackCallback(content);
  }

  dataHandler(frame: string) {
    this.ws.send(frame);

    if (this._resetOnTimestamp !== null && this._resetOnTimestamp > Date.now()) {
      this.status.resetTime();
      this.media.restart(this.status.duration);
    } else {
      this._resetOnTimestamp = null;
      this.media.restart(this.status.remaining);
    }

    (async () => {
      const dataBlob = await this.media.dataUrlToBlob(frame);
      this.dataCallback(this.status, dataBlob);
    })();
  }

  private async stopHandler(completed: boolean) {
    this._resetOnTimestamp = null;

    if (this.ws) this.ws.send(null, true); // send final to FP

    if (this.status !== null) {
      this.status.done = completed;
      this.stopCallback(this.status);
    }
  }

  private async errorHandler(event: Event) {
    if (this.debugMode) {
      console.error("MediaRecorder Error, event:");
      console.error(event);
    }
    console.error(`${event.timeStamp}: MediaRecorder error!`);
  }

  private async offlineHandler() {
    await this.stop();
    const error = new LiveError({
      exc_type: "ConnectionError",
      info: "The internet connection was lost",
      exc_code: 1001,
      exc_action: LiveErrorAction.STOP,
    });
    this.feedbackCallback(error);
    if (this.debugMode) console.warn("Internet connection was lost!");
  }

  private async onlineHandler() {
    if (this.debugMode) console.log("Internet connection restored!");
  }

  async attach(videoElementId: string, attachOptions?: AttachOptions) {
    if (this.debugMode) console.log("DEBUG: Attaching sdk...");

    const options: AttachOptions = {
      cameraId: null,
      enableFaceLandmark: true,
      flipFaceLandmarkHorizontal: false,
      ...(attachOptions ?? {}),
    };

    this._attachOptions = options;

    this.createMediaService(videoElementId);

    await this.media.openCameraStream(options.cameraId);
    await this.media.awaitVideoLoading();

    const streamEndpoint = await this.api.getStreamingEndpoint();
    this.ws = new IntelliProveWebsocketService(new WebsocketSettings(streamEndpoint));

    // add offline/online event listeners
    window.addEventListener("online", (_) => this.onlineHandler());
    window.addEventListener("offline", (_) => this.offlineHandler());

    if (this.debugMode) {
      var videoEl = this.media.videoEl;
      this.media.awaitVideoLoading().then(() => {
        console.debug(`Loaded camera stream with resolution ${videoEl.videoWidth} x ${videoEl.videoHeight}`);
      });
    }

    if (options.cameraId) {
      try {
        this.getCameraById(options.cameraId);
      } catch (e) {
        console.warn(`Failed to get camera by ID: ${options.cameraId}. Does it still exist?`);
        console.warn(e);
      }
    }

    if (options.enableFaceLandmark) {
      await this.startFaceAnnotation(options.flipFaceLandmarkHorizontal);
    }
  }

  async detach() {
    if (this.debugMode) console.log("DEBUG: Detaching sdk...");

    if (this.media !== null) {
      this.media.detach();
    }

    if (this.ws !== null) {
      this.ws.close();
    }

    this.media = null;
    this.ws = null;

    if (this._timeStopMessage !== null) {
      clearTimeout(this._timeStopMessage);
    }
    this._timeStopMessage = null;

    if (this.faceLandmark !== null) {
      this.faceLandmark.stop();
      this.faceLandmark = null;
    }

    this.status = null;
  }

  async start(length: number = 20_000, streamingMetadata: StreamingMetadata | null = null) {
    if (length < SDK_CONFIG.minDuration) {
      throw new IntelliProveSDKError(`Length of recording needs to be at least ${SDK_CONFIG.minDuration}ms`, 1);
    }

    if (length > SDK_CONFIG.maxDuration) {
      throw new IntelliProveSDKError(`Length of recording needs to be at most ${SDK_CONFIG.maxDuration}ms`, 1);
    }

    streamingMetadata = streamingMetadata || new StreamingMetadata();

    // quality check
    const qc = await this.qualityCheck(streamingMetadata);
    if (!qc.success()) {
      throw new QualityError("Could not start recording because the quality check failed.", qc);
    }

    // Create stream
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.status = await this.api.createStream(streamingMetadata, timezone);

    // Connect web socket
    try {
      await this.ws.connect(this.status.uuid, this.messageHandler);
    } catch (_) {
      throw new IntelliProveSDKError("Could not connect to websocket!", ErrorType.Connection);
    }

    // Start recording
    this.status.startTime = Date.now();
    this.status.stopTime = this.status.startTime + length;
    this.status.duration = length;
    this.media.record(length, this.dataHandler, this.stopHandler);
  }

  async stop(): Promise<void> {
    if (this.debugMode) console.log("DEBUG: Stopping stream...");
    this.stopHandler(false); // Forward to stopHandler
    if (this.media) this.media.stop();
    if (this.ws) this.ws.close();
  }

  /**
   * @deprecated This method will be removed in future versions. Replace with the 'stop' method
   */
  async cancel(): Promise<void> {
    await this.stop();
  }

  async finish(): Promise<BiomarkersResponse | UnprocessableVideoResponse> {
    if (this.debugMode) console.log("DEBUG: Finishing stream...");
    if (this.status === null) {
      throw new IntelliProveSDKError("Cannot finish measurement since there is no acitve measurement.", ErrorType.SDK);
    }
    return this.getResults(this.status!.uuid);
  }

  async getResults(uuid: string): Promise<BiomarkersResponse | UnprocessableVideoResponse> {
    if (this.debugMode) console.log("DEBUG: Getting results...");
    try {
      for (let j = 0; j < RETRIES.results; j++) {
        const resp = await this.api.getResults(uuid);
        if (resp !== null) {
          return resp;
        }
        await wait(200);
      }
      throw new IntelliProveSDKError("Failed to get results from API!", ErrorType.Connection);
    } catch (err) {
      this._handleError(err);
    }
  }

  async getResultsCount(
    from: Date | null = null,
    until: Date | null = null,
	performer: string | null = null,
    patient: string | null = null,
  ): Promise<ResultsCountResponse> {
    return this.api.getResultsCount(from, until, performer, patient);
  }

  async getCameraById(cameraId: string): Promise<MediaDeviceInfo> {
    // Get available cameras
    const cameras = await this.media.getAvailableCameras();
    const matched = cameras.filter((c) => c.deviceId === cameraId);
    if (matched.length === 0) {
      throw new IntelliProveSDKError("Provided cameraId does not exist for current device!", ErrorType.SDK);
    }
    const camera = matched[0];
    if (this.selectedCameraIdx === cameras.indexOf(camera)) {
      return;
    }
    this.selectedCameraIdx = cameras.indexOf(camera);
    return camera;
  }

  async changeCamera(cameraId: string) {
    if (this.media === null) {
      throw new IntelliProveSDKError("Please attach before changing your camera!", ErrorType.SDK);
    }

    // update selectedCameraIndex by camera id
    this.getCameraById(cameraId);

    const videoId = this.media.videoEl.id;
    const attachOptions = this._attachOptions || {};
    attachOptions.cameraId = cameraId;

    await this.detach();
    await this.attach(videoId, attachOptions);
  }

  async nextCamera(): Promise<string | undefined> {
    if (this.media === null) {
      throw new IntelliProveSDKError("Please attach before calling 'nextCamera'!", ErrorType.SDK);
    }

    // Get available cameras
    const cameras = await this.media.getAvailableCameras();
    this.selectedCameraIdx = cameras.length - 1 === this.selectedCameraIdx ? 0 : this.selectedCameraIdx + 1;

    const videoId = this.media.videoEl.id;
    const attachOptions = this._attachOptions || {};
    attachOptions.cameraId = cameras[this.selectedCameraIdx].deviceId;

    await this.detach();
    await this.attach(videoId, attachOptions);

    return attachOptions.cameraId;
  }

  async qualityCheck(metadata: StreamingMetadata | null = null): Promise<QualityResponse> {
    if (this.debugMode) console.log("DEBUG: Performing quality check...");
    if (this.media === null) {
      throw new IntelliProveSDKError("Please attach before calling 'qualityCheck'!", ErrorType.SDK);
    }

    const snapshot = await this.media.qualityCheckImage();
    return await this.api.qualityCheck(snapshot, metadata);
  }

  async getBuckets(uuid: string): Promise<BucketsResponse> {
    if (this.debugMode) console.log("DEBUG: Getting buckets...");
    try {
      const buckets = await this.api.getBuckets(uuid);
      if (buckets === null) {
        throw new IntelliProveSDKError("Measurement not found", ErrorType.SDK);
      }
      return buckets;
    } catch (err) {
      this._handleError(err, true);
    }
  }

  async getBucketFeedback(uuid: string, language: string = "en", includeTips: boolean = true): Promise<BucketFeedbackResponse> {
    if (this.debugMode) console.log("DEBUG: Getting buckets...");
    try {
      const response = await this.api.getBucketFeedback(uuid, language, includeTips);
      if (response === null) {
        throw new IntelliProveSDKError("Measurement with uuid not found", ErrorType.SDK);
      }

      return response;
    } catch (err) {
      this._handleError(err, true);
    }
  }

  async getCurrentUser(): Promise<UserInfo> {
    if (this.debugMode) console.log("DEBUG: Getting current user info...");
    try {
      const user = await this.api.getUser();
      if (user === null) {
        throw new IntelliProveSDKError("Failed to get user information.", ErrorType.SDK);
      }
      return user;
    } catch (err) {
      this._handleError(err);
    }
  }

  async getPluginSettings(): Promise<PluginSettings> {
    if (this.debugMode) console.log("DEBUG: Getting plugin settings...");
    try {
      const settings = await this.api.getPluginSettings();
      if (settings === null) {
        throw new IntelliProveSDKError("Failed to fetch plugin settings for customer", ErrorType.SDK);
      }
      return settings;
    } catch (err) {
      this._handleError(err);
    }
  }

  async getQuestions(ids: Array<number>): Promise<Array<QuestionItem>> {
    if (this.debugMode) console.log("DEBUG: Getting questions...");
	if (ids.length === 0) {
	  throw new IntelliProveSDKError("You need to provide at least 1 question id", ErrorType.SDK);
	}

	try {
	  const questions = await this.api.getQuestions(ids);
	  if (questions === null) {
		throw new IntelliProveSDKError("Failed to fetch questions by ids", ErrorType.SDK);
	  }
	  return questions;
	} catch (err) {
	  this._handleError(err);
	}
  }
  
  async answerQuestion(id: number, health_scan_id: string, answer: ChoosenAnswer) {
    if (this.debugMode) console.log("DEBUG: Saving question answer...");
	try {
	  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	  const req: QuestionAnswerRequest = {
		'health_scan_id': health_scan_id,
		'answer': answer,
		'timezone': timezone,
	  };
	  const ok = await this.api.answerQuestion(id, req);
	  if (!ok) {
		throw new IntelliProveSDKError("Failed to save question answer!", ErrorType.SDK);
	  }
	} catch (err) {
	  this._handleError(err);
	}
  }

  async getPluginTranslations(locale: string): Promise<PluginTranslations> {
    if (this.debugMode) console.log("DEBUG: Getting plugin translations...");
    try {
      const translations = await this.api.getPluginTranslations(locale);
      if (translations === null) {
        throw new IntelliProveSDKError("Failed to fetch plugin translations", ErrorType.SDK);
      }
      return translations;
    } catch (err) {
      this._handleError(err);
    }
  }

  async startFaceAnnotation(flipLandmarkHorizontal = false) {
    if (!this.media) {
      throw new IntelliProveSDKError("MediaService should be instantiated first", ErrorType.SDK);
    }

    this.faceLandmark = new FaceLandmarkService(this.media.videoEl, {
      flipHorizontal: flipLandmarkHorizontal,
      debugMode: this.debugMode,
    });

    if (this.debugMode) console.log(`DEBUG: FaceLandmark awaiting for camera ready`);
    await this.media.awaitVideoLoading();
    if (this.debugMode) console.log(`DEBUG: FaceLandmark awaiting for camera ready: OK`);

    await this.faceLandmark.start();
  }

  async stopFaceAnnotation() {
    this.faceLandmark.stop();
  }
}
