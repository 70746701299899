import { useEffect, useState } from "react";
import cx from "classnames";
import { interpolateNumber } from "d3";

import { IconFromName } from "@/components/AssetsManager/AssetsManager";
import { iResultBiomarkerCard } from "../ResultBiomarkerCard.interface";

export const BiomarkerLinearCard = ({ biomarker }: iResultBiomarkerCard) => {
  const [metricValue, setMetricValue] = useState<number>(0);
  const interpolate = interpolateNumber(0, 100);

  const calculateMetricPosition = () => {
    let value = parseInt(biomarker.value);

    if (isNaN(value)) {
      return 0;
    }

    if ((biomarker.range ?? []).length < 2) {
      return 0;
    }

    const [min, max] = biomarker.range;

    // Ensure the value is within the given range
    value = Math.min(Math.max(value, min), max);

    // Normalize value to 0-1
    const norm = (value - min) / (max - min);

    return interpolate(norm);
  };

  useEffect(() => {
    setTimeout(() => setMetricValue(calculateMetricPosition()), 100);
  }, []);

  return (
    <div className="measurement-result-line">
      <div className="sm:aspect-2/1 relative w-full">
        <div className="w-full h-full justify-center flex">
          <div className="flex flex-row items-center">
            <div className="mr-1 sm:mr-2">
              <IconFromName name={biomarker.name} className="w-[10vw] sm:w-[56px]" />
            </div>
            <div className="flex items-center justify-center">
              <span
                className={cx("text-[6vw] font-bold sm:text-4xl", {
                  "text-functional-primary-500": biomarker.zone === "bad",
                  "text-functional-secondary-500": biomarker.zone === "neutral",
                  "text-brand-secondary": !["bad", "neautral"].includes(biomarker.zone),
                })}
              >
                {biomarker.value}
              </span>
              <span className="ml-2 text-support-500 text-[3vw] sm:text-[20px]">{biomarker.unit}</span>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col justify-between mt-[2vw] sm:mt-[unset]">
          <div className="relative h-[12px] flex items-center">
            <img src={require(`@/images/result-line-${biomarker.name}.png`)} className={cx("w-full")} alt="" />
            <div className="measurement-result-value" style={{ left: `${metricValue}%` }}></div>
          </div>

          <div className="flex flex-row justify-between">
            {biomarker.range.map((range) => {
              return (
                <span key={range.toString()} className="text-support-500 text-[3vw] sm:text-[13px]">
                  {range}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
