import cx from "classnames";
import { IconCircleNext } from "../Icons";
import { useTheme } from "@/context/theme";
import { ReactNode, useCallback } from "react";
import { HandleSwipe, SwipeDirection, TouchKind } from "@/utils/mobile";
import { IconDoubleArrow } from "../Icons/icon_double_arrow";

export type JSXFunc = () => JSX.Element;

export interface iPageSlider {
  activeSlide: number;
  slideChanged: (i: number) => void;
  className?: string;
  children: ReactNode[];
  styling?: {
    arrowButtons?: boolean;
    dots?: boolean;
    skipButton?: boolean;
  };
}

export const PageSlider = ({
  activeSlide,
  slideChanged,
  className,
  children,
  styling = { arrowButtons: true, dots: true, skipButton: true },
}: iPageSlider) => {
  const theme = useTheme();

  const swipeHandler = (direction: SwipeDirection) => {
    if (direction == SwipeDirection.LeftRight) {
      ChangeActiveSlide(-1);
    } else if (direction == SwipeDirection.RightLeft) {
      ChangeActiveSlide(1);
    }
  };

  const ChangeActiveSlide = (n: number) => {
    if (n === 0) return;

    if (n > 0 && activeSlide < children.length - 1) {
      slideChanged(activeSlide + n);
      return;
    }

    if (n < 0 && activeSlide > 0) {
      slideChanged(activeSlide + n);
      return;
    }
  };

  const NextArrow = useCallback(
    ({ onClick }: any) => (
      <button
        className={cx("sm:min-w-[64px] flex justify-center items-center", {
          "opacity-20 cursor-not-allowed": activeSlide === children.length - 1,
        })}
        onClick={() => {
          onClick();
        }}
      >
        <IconCircleNext background="transparent" color={theme.value.brand_primary} />
      </button>
    ),
    [activeSlide, children.length, theme.value.brand_primary],
  );

  const PrevArrow = useCallback(
    ({ onClick }: any) => (
      <button
        className={cx("rotate-180 sm:min-w-[64px] flex justify-center items-center", {
          "opacity-20 cursor-not-allowed": activeSlide === 0,
        })}
        onClick={() => {
          onClick();
        }}
      >
        <IconCircleNext background="transparent" color={theme.value.brand_primary} />
      </button>
    ),
    [activeSlide, theme.value.brand_primary],
  );

  return (
    <div className={cx("flex flex-grow flex-col", className)}>
      <div className="w-full flex flex-grow" id="slider-wrapper">
        {styling.arrowButtons && <PrevArrow onClick={() => ChangeActiveSlide(-1)} />}

        <div
          className="w-full flex-grow px-2"
          id="slider-container"
          onTouchStart={(evt) => HandleSwipe(evt, TouchKind.Start, swipeHandler)}
          onTouchEnd={(evt) => HandleSwipe(evt, TouchKind.Stop, swipeHandler)}
        >
          {children.map((child, idx) => {
            return (
              <div key={idx} className={cx("w-full h-full", { hidden: activeSlide !== idx })}>
                {child}
              </div>
            );
          })}
        </div>

        {styling.arrowButtons && <NextArrow onClick={() => ChangeActiveSlide(1)} />}
      </div>

      <div className="flex justify-center gap-8">
        <div id="slider-dots" className="flex justify-evenly gap-3 py-3 items-center">
          {children.map((_, idx) => {
            return (
              <span
                onClick={() => slideChanged(idx)}
                key={idx}
                className={cx(
                  "w-3 h-3 rounded-[100px] transition-colors cursor-pointer",
                  idx === activeSlide ? "bg-functional-secondary-500" : "bg-support-300",
                )}
              />
            );
          })}
        </div>

        {styling.skipButton && activeSlide < children.length - 1 && (
          <IconDoubleArrow
            className="cursor-pointer sm:hidden"
            onClick={() => {
              slideChanged(children.length - 1);
            }}
          />
        )}
      </div>
    </div>
  );
};
