import { JsonObject } from "../interfaces";

export enum BiomarkerZone {
  Good = "good",
  Neutral = "neutral",
  Bad = "bad",
  NotAvailable = "not_available",
}

export enum BucketStatus {
  Good = "good",
  Neutral = "neutral",
  Bad = "bad",
}

export enum Optimum {
  Low = "low",
  Middle = "middle",
  High = "high",
}

export class BucketBiomarker {
  name: string;
  value: string;
  unit: string;
  zone: BiomarkerZone;
  range: number[];
  optimum: Optimum;

  constructor(json: JsonObject) {
    this.name = json.name;
    this.value = json.value;
    this.unit = json.unit;
    this.zone = json.zone as BiomarkerZone;
    this.range = json.range;
    this.optimum = json.optimum as Optimum;
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}

export class BucketHistory {
  biomarkers: Object[];
  timestamp_epoch: number;
  timestamp: Date;

  constructor(json: JsonObject) {
    this.biomarkers = json.biomarkers;
    this.timestamp_epoch = json.timestamp_epoch;
    this.timestamp = new Date(json.timestamp);
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}

export class BucketResult {
  name: string;
  timestamp_epoch: number;
  timestamp: Date;
  status: BucketStatus;
  biomarkers: BucketBiomarker[];
  history: BucketHistory[];

  constructor(json: JsonObject) {
    this.name = json.name;
    this.timestamp_epoch = json.timestamp_epoch;
    this.timestamp = new Date(json.timestamp);
    this.status = json.status as BucketStatus;
    this.biomarkers = json.biomarkers.map((b: JsonObject) => new BucketBiomarker(b));
    this.history = json.history.map((h: JsonObject) => new BucketHistory(h));
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}

export default class BucketsResponse {
  uuid: string;
  buckets: BucketResult[];

  constructor(json: JsonObject) {
    this.uuid = json.uuid;
    this.buckets = json.buckets.map((b: JsonObject) => new BucketResult(b));
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}
