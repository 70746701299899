import { BucketSummary } from "intelliprove-streaming-sdk";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { IconAi } from "@/components/Icons/icon_ai";
import { useTheme } from "@/context/theme";

export interface iResultfeedback {
  feedback?: BucketSummary | null;
}

export const ResultFeedback = ({ feedback }: iResultfeedback) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <SkeletonTheme baseColor={theme.value.dark_mode ? "#1A1A1A" : "#F7F9FD"} highlightColor={theme.value.brand_tertiary}>
      <div className="flex flex-col w-full items-start justify-stretch mt-6 gap-[20px] sm:flex-row sm:gap-[32px] sm:mt-10">
        <div className="flex flex-1 flex-col rounded-[20px] items-center justify-start p-[24px] flex-nowrap self-stretch bg-brand-tertiary shadow-md border-[1px]">
          <span className="text-primary text-support-500 text-lg font-semibold mb-4 flex items-center">
            {feedback || feedback === null ? (
              <>
                {t("feedback_title")}
                <IconAi className="ml-2" fill={theme.value.support_500} />
              </>
            ) : (
              // feedback === undefined -> loading
              <Skeleton width="100px" height="24px" />
            )}
          </span>
          <span className="text-primary text-support-500 leading-6 w-full text-center">
            {feedback ? feedback.summary : feedback === null ? t("no_feedback") : <Skeleton count={5} width="100%" height="20px" />}
          </span>
        </div>
        <div className="flex flex-1 flex-col rounded-[20px] items-center justify-start p-[24px] flex-nowrap self-stretch shadow-md bg-brand-tertiary border-[1px]">
          <span className="text-primary text-support-500 text-lg font-semibold mb-4 flex items-center">
            {feedback || feedback === null ? (
              <>
                {t("tip_title")}
                <IconAi className="ml-2" fill={theme.value.support_500} />
              </>
            ) : (
              <Skeleton width="100px" height="24px" />
            )}
          </span>
          <span className="text-primary text-support-500 leading-6 w-full text-center">
            {feedback ? feedback.tip : feedback === null ? t("no_tip") : <Skeleton count={5} width="100%" height="20px" />}
          </span>
        </div>
      </div>
    </SkeletonTheme>
  );
};
