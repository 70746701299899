import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { Button, ButtonType } from "../Button/Button";
import { IconChevronLeft } from "@/components/Icons/Icon_chevron_left";
import { useTheme } from "@/context/theme";

interface IBackButton {
  className?: string;
  onClick?: Function;
  darkMode?: boolean;
  type?: ButtonType;
}

export const BackButton = ({ className = "", onClick, darkMode = false, type }: IBackButton) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className="flex" onClick={() => (onClick ? onClick() : navigate(-1))}>
      <Button
        type={type}
        text={<span className="hidden sm:block">{t("return")}</span>}
        className={cx("uppercase min-h-[38px] h-[38px] w-[38px] sm:w-[unset] sm:min-h-[40px]", className)}
        prefix={<IconChevronLeft className="fill-current" />}
      />
    </div>
  );
};
