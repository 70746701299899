import { useTheme } from "@/context/theme";
import { iIconOnboarding } from "./type";

export const IconOnboarding2 = ({ ...props }: iIconOnboarding) => {
  const theme = useTheme();

  return (
    <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="27.7236" y="53" width="154.556" height="105.999" rx="9" stroke={theme.value.support_500} strokeWidth="4" />
      <path
        d="M20 154.927H190V154.927C190 158.833 186.833 162 182.926 162H27.0736C23.167 162 20 158.833 20 154.927V154.927Z"
        fill={theme.value.support_500}
      />
      <rect x="93.1943" y="157.283" width="23.6111" height="2.35787" rx="1.17893" fill="white" />
      <path
        d="M19.2857 180H9C6.79086 180 5 178.209 5 176V165.827"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M19.2864 172.913H14.1436C13.039 172.913 12.1436 172.018 12.1436 170.913V165.826"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M190 36H200.286C202.495 36 204.286 37.7909 204.286 40V50.1732"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M190 43.0869H195.143C196.247 43.0869 197.143 43.9823 197.143 45.0869V50.1735"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  );
};
