export const IconAi = ({
  fill = "#3A79FF",
  ...props
}: {
  fill?: string;
  className?: string;
}) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0923 2.34375C16.3931 2.34375 16.6392 2.58984 16.6392 2.89062V4.53125H18.2798C18.5806 4.53125 18.8267 4.77734 18.8267 5.07812C18.8267 5.37891 18.5806 5.625 18.2798 5.625H16.6392V7.26562C16.6392 7.56641 16.3931 7.8125 16.0923 7.8125C15.7915 7.8125 15.5454 7.56641 15.5454 7.26562V5.625H13.9048C13.604 5.625 13.3579 5.37891 13.3579 5.07812C13.3579 4.77734 13.604 4.53125 13.9048 4.53125H15.5454V2.89062C15.5454 2.58984 15.7915 2.34375 16.0923 2.34375ZM16.0923 12.1875C16.3931 12.1875 16.6392 12.4336 16.6392 12.7344V14.375H18.2798C18.5806 14.375 18.8267 14.6211 18.8267 14.9219C18.8267 15.2227 18.5806 15.4688 18.2798 15.4688H16.6392V17.1094C16.6392 17.4102 16.3931 17.6562 16.0923 17.6562C15.7915 17.6562 15.5454 17.4102 15.5454 17.1094V15.4688H13.9048C13.604 15.4688 13.3579 15.2227 13.3579 14.9219C13.3579 14.6211 13.604 14.375 13.9048 14.375H15.5454V12.7344C15.5454 12.4336 15.7915 12.1875 16.0923 12.1875ZM6.00244 8.64648L3.1792 9.95215L6.00244 11.2544C6.23828 11.3638 6.42627 11.5518 6.53564 11.7876L7.83789 14.6108L9.14014 11.7876C9.24951 11.5518 9.4375 11.3638 9.67334 11.2544L12.4966 9.95215L9.67334 8.6499C9.4375 8.54053 9.24951 8.35254 9.14014 8.1167L7.83789 5.29346L6.53564 8.1167C6.42627 8.35254 6.23828 8.54053 6.00244 8.6499V8.64648ZM5.54443 12.249L1.64453 10.4478C1.44971 10.3589 1.32666 10.1641 1.32666 9.95215C1.32666 9.74023 1.44971 9.54541 1.64453 9.45654L5.54443 7.65527L7.34229 3.75537C7.43115 3.56055 7.62598 3.4375 7.83789 3.4375C8.0498 3.4375 8.24463 3.56055 8.3335 3.75537L10.1348 7.65527L14.0347 9.45312C14.2295 9.54199 14.3525 9.73682 14.3525 9.94873C14.3525 10.1606 14.2295 10.3555 14.0347 10.4443L10.1382 12.249L8.33691 16.1455C8.24805 16.3403 8.05322 16.4634 7.84131 16.4634C7.62939 16.4634 7.43457 16.3403 7.3457 16.1455L5.54443 12.249Z"
        fill={fill}
      />
    </svg>
  );
};
