import { TouchEvent } from "react";

interface iTouchData {
  start?: { x: number; y: number };
  end?: { x: number; y: number };
}

export enum TouchKind {
  Start = 0,
  Stop = 1,
}

export enum SwipeDirection {
  TopDown = 0,
  BottomUp = 1,
  LeftRight = 2,
  RightLeft = 3,
}

const touchData: iTouchData = {};

export const HandleSwipe = (
  event: TouchEvent<HTMLDivElement>,
  kind: TouchKind,
  callback: (direction: SwipeDirection) => void,
  minMovement: number = 30,
) => {
  let touch;

  switch (kind) {
    case TouchKind.Start:
      touch = event.touches[0];
      touchData["start"] = { x: touch.screenX, y: touch.screenY };
      touchData["end"] = undefined;
      return;
    case TouchKind.Stop:
      touch = event.changedTouches[0];
      touchData["end"] = { x: touch.screenX, y: touch.screenY };

      if (touchData.start && touchData.end) {
        let xDiff = touchData.end.x - touchData.start.x;
        let yDiff = touchData.end.y - touchData.start.y;

        if (Math.abs(xDiff) < minMovement && Math.abs(yDiff) < minMovement) {
          return;
        }

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          // X is dominant
          if (xDiff < 0) {
            callback(SwipeDirection.RightLeft);
          } else {
            callback(SwipeDirection.LeftRight);
          }
        } else {
          // Y is dominant
          if (yDiff < 0) {
            callback(SwipeDirection.BottomUp);
          } else {
            callback(SwipeDirection.TopDown);
          }
        }

        // clear
        delete touchData["start"];
        delete touchData["end"];
      }
      return;
  }
};
