import { JsonObject } from "../interfaces";

export default class UserInfo {
  email: string;
  group: string;
  customer: string;

  constructor(json: JsonObject) {
    this.email = json.email;
    this.group = json.group;
    this.customer = json.customer;
  }

  hasEmail(): boolean {
    return this.email !== "" && this.email.includes("@");
  }

  isAdmin(): boolean {
    return this.group === "admin";
  }

  isContributor(): boolean {
    return this.group === "contributor";
  }

  isUser(): boolean {
    return !this.isAdmin() && !this.isContributor();
  }

  json(): JsonObject {
    return JSON.parse(JSON.stringify(this));
  }
}
