import { useTheme } from "@/context/theme";
import { iIconOnboarding } from "./type";

export const IconOnboarding5 = ({ ...props }: iIconOnboarding) => {
  const theme = useTheme();

  return (
    <svg width="210" height="210" viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M59.4001 177H49C46.7909 177 45 175.209 45 173V162.478"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M150.6 177H161C163.209 177 165 175.209 165 173V162.478"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M59.4001 33H49C46.7909 33 45 34.7909 45 37V47.5217"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M150.6 33H161C163.209 33 165 34.7909 165 37V47.5217"
        stroke={theme.value.functional_tertiary_500}
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M104.937 128.053C127.128 128.053 145.119 109.483 145.119 86.5768C145.119 63.6709 127.128 45.1006 104.937 45.1006C82.7451 45.1006 64.7539 63.6709 64.7539 86.5768C64.7539 109.483 82.7451 128.053 104.937 128.053ZM104.937 50.2851C124.356 50.2851 140.097 66.5321 140.097 86.5768C140.097 106.621 124.356 122.868 104.937 122.868C85.5171 122.868 69.7768 106.621 69.7768 86.5768C69.7768 66.5321 85.5171 50.2851 104.937 50.2851Z"
        fill={theme.value.support_500}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.135 161.646L63.0757 161.686C61.9891 162.418 60.5211 162.121 59.7968 161.024C59.0724 159.927 59.366 158.445 60.4526 157.713C64.7927 154.791 77.4019 146.397 105 146.397C132.597 146.397 145.207 154.791 149.547 157.713C150.633 158.445 150.927 159.927 150.203 161.024C149.478 162.121 148.01 162.418 146.924 161.686L146.864 161.646C143.035 159.068 131.309 151.173 105 151.173C78.6904 151.173 66.9643 159.068 63.135 161.646Z"
        fill={theme.value.support_500}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.716 101.428C122.016 102.384 121.49 103.404 120.542 103.706C114.747 105.551 110.053 106.513 105.225 106.513C100.403 106.513 95.5637 105.553 89.4825 103.714C88.5303 103.425 87.9899 102.414 88.2756 101.453C88.5613 100.493 89.5647 99.9481 90.5169 100.236C96.4358 102.027 100.897 102.882 105.225 102.882C109.547 102.882 113.852 102.029 119.458 100.244C120.406 99.942 121.417 100.472 121.716 101.428Z"
        fill={theme.value.functional_tertiary_500}
      />
    </svg>
  );
};
