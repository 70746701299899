import { useTheme } from "@/context/theme";
import { SVGProps } from "react";

export const IconCircleKey = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1958_128)">
        <path
          d="M44 5.5C54.2108 5.5 64.0035 9.55624 71.2236 16.7764C78.4438 23.9965 82.5 33.7892 82.5 44C82.5 54.2108 78.4438 64.0035 71.2236 71.2236C64.0035 78.4438 54.2108 82.5 44 82.5C33.7892 82.5 23.9965 78.4438 16.7764 71.2236C9.55624 64.0035 5.5 54.2108 5.5 44C5.5 33.7892 9.55624 23.9965 16.7764 16.7764C23.9965 9.55624 33.7892 5.5 44 5.5ZM44 88C55.6695 88 66.8611 83.3643 75.1127 75.1127C83.3643 66.8611 88 55.6695 88 44C88 32.3305 83.3643 21.1389 75.1127 12.8873C66.8611 4.6357 55.6695 0 44 0C32.3305 0 21.1389 4.6357 12.8873 12.8873C4.6357 21.1389 0 32.3305 0 44C0 55.6695 4.6357 66.8611 12.8873 75.1127C21.1389 83.3643 32.3305 88 44 88Z"
          fill="#092187"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.0996 44.3196C65.0776 48.296 61.8362 51.5016 57.8598 51.4796C53.8834 51.4575 50.6778 48.2161 50.6998 44.2398C50.7219 40.2634 53.9632 37.0577 57.9396 37.0798C61.916 37.1018 65.1216 40.3432 65.0996 44.3196ZM69.0996 44.3417C69.0653 50.5272 64.0232 55.5138 57.8377 55.4795C53.3809 55.4548 49.5466 52.8303 47.7643 49.0516C47.5154 48.5239 47.0028 48.1552 46.4194 48.152L32.1783 48.0731C31.4051 48.0688 30.7749 48.6921 30.7706 49.4653L30.7691 49.7294C30.7648 50.5026 30.1346 51.1259 29.3614 51.1216L29.1614 51.1205C28.3882 51.1162 27.7649 50.486 27.7692 49.7128L27.7706 49.4487C27.7749 48.6755 27.1516 48.0452 26.3784 48.0409L25.8784 48.0382C25.1052 48.0339 24.475 48.6572 24.4707 49.4304L24.4576 51.7944C24.4533 52.5676 23.823 53.191 23.0498 53.1867L22.8498 53.1856C22.0766 53.1813 21.4533 52.551 21.4576 51.7778L21.4715 49.2641C21.4754 48.5736 20.9187 48.0107 20.2282 48.0069C19.5377 48.003 18.981 47.4402 18.9848 46.7496L18.9923 45.4C18.9966 44.6268 19.6268 44.0035 20.4 44.0077L45.3083 44.1458C46.0784 44.1501 46.6982 43.5283 46.8013 42.7651C47.5442 37.27 52.2687 33.0483 57.9618 33.0798C64.1473 33.1141 69.1338 38.1562 69.0996 44.3417Z"
          fill="#092187"
        />
      </g>
      <defs>
        <clipPath id="clip0_1958_128">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
