import { useTheme } from "@/context/theme";
import { toInteger } from "lodash";

export const IconChevronLeft = ({ ...props }: { className?: string }) => {
  const theme = useTheme();

  return (
    <svg width="6" height="12" viewBox="0 0 6 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.220215 5.46875C-0.0727539 5.76162 -0.0727539 6.23724 0.220215 6.53011L4.72021 11.0286C5.01318 11.3215 5.48896 11.3215 5.78193 11.0286C6.0749 10.7357 6.0749 10.2601 5.78193 9.96724L1.81162 5.99826L5.77959 2.02928C6.07256 1.73641 6.07256 1.26078 5.77959 0.967914C5.48662 0.675043 5.01084 0.675043 4.71787 0.967914L0.217871 5.46641L0.220215 5.46875Z" />
    </svg>
  );
};
