import { useTheme } from "@/context/theme";
import { SVGProps } from "react";

export const IconSleepQuality = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M29.9932 13.1038H33.3178C34.339 13.1038 34.9547 14.2371 34.4004 15.0966L31.2785 19.9369C30.7241 20.7964 31.3398 21.9297 32.3611 21.9297H36.0206M41.6715 19.7746H44.9961C46.0174 19.7746 46.6331 20.9079 46.0788 21.7674L42.9568 26.6077C42.4025 27.4672 43.0182 28.6005 44.0395 28.6005H47.6989M52.9726 13.8906H56.2972C57.3185 13.8906 57.9342 15.0239 57.3798 15.8834L54.2579 20.7237C53.7036 21.5832 54.3193 22.7165 55.3405 22.7165H59M51.5758 45.4684C53.2995 45.4684 54.989 45.3279 56.6325 45.0581C57.771 44.8712 58.6607 46.0929 57.9715 47.0199C52.5812 54.271 43.7345 59 33.7349 59C17.3127 59 4 46.2455 4 30.5121C4 18.5475 11.6986 8.3057 22.6087 4.08544C23.685 3.66911 24.6346 4.85423 24.1687 5.9118C22.6697 9.31467 21.8409 13.0548 21.8409 16.9804C21.8409 32.7139 35.1537 45.4684 51.5758 45.4684Z"
        stroke={theme.value.support_500}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
