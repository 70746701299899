import { JsonObject } from "../interfaces";

export enum LiveErrorAction {
  CONTINUE = "continue",
  RESET = "reset",
  STOP = "stop",
}

export default class LiveError {
  type: string;
  info: string;
  error_code: number;
  action: LiveErrorAction;
  resetOn: number | null;

  constructor(json: JsonObject) {
    this.type = json.exc_type;
    this.info = json.info;
    this.action = json.exc_action as LiveErrorAction;
    this.error_code = json.exc_code ?? 99;
    this.resetOn = json.reset_on ?? null;
  }
}
