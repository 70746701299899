import cx from 'classnames';
import {twMerge} from 'tailwind-merge';

interface ITitle {
  size?: number;
  children: any;
  italic?: boolean;
  underline?: boolean;
  className?: string;
  responsive?: boolean;
  [key: string]: any;
}

interface IText {
  size?: number;
  children: any;
  italic?: boolean;
  underline?: boolean;
  className?: string;
  [key: string]: any;
}

const Title = ({
  size,
  children,
  italic,
  underline,
  className,
  responsive,
  ...props
}: ITitle) => {
  const baseStyle = cx(
    twMerge(`font-bold ${className}`),
    underline && 'border-b',
    italic && 'italic',
  );

  // 30px
  let title = (
    <h1
      className={`text-3xl ${baseStyle}`} 
      {...props}>
      {children}
    </h1>
  );

  switch (size) {
    // 24px
    case 2:
      title = (
        <h2 className={twMerge(`text-2xl ${baseStyle}`)} {...props}> 
          {children}
        </h2>
      );
      break;
    // 20px
    case 3:
      title = (
        <h3 className={twMerge(`text-xl ${baseStyle}`)} {...props}>
          {children}
        </h3>
      ); 
      break;
    // 18px      
    case 4:
      title = (
        <h4 className={twMerge(`text-lg ${baseStyle}`)} {...props}>
          {children}
        </h4>
      );
      break;
    // 16px
    case 5:
      title = (
        <h5 className={twMerge(`text-base ${baseStyle}`)} {...props}>
          {children}
        </h5>
      );
      break;
    // 14px
    case 6:
      title = (
        <h6 className={twMerge(`text-sm ${baseStyle}`)} {...props}>
          {children}
        </h6>
      );
      break;
    // 12px
    case 7:
      title = (
        <h6 className={twMerge(`text-xs ${baseStyle}`)} {...props}>
          {children}
        </h6>
      ); 
      break;
  }

  return title;
};

const Text = ({
  children,
  italic,
  underline,
  className,
  size = 0,
  ...props
}: IText) => {
  const baseStyle = cx(
    'font-normal',
    underline && 'border-b',
    italic && 'italic',
  );
  let style = `text-xs ${baseStyle}`; // 12px

  switch (size) {
    case 1:
      style = `text-2xl ${baseStyle}`; // 24px
      break;
    case 2:
      style = `text-xl ${baseStyle}`; // 20px
      break;
    case 3:
      style = `text-lg ${baseStyle}`; // 18px
      break;
    case 4:
      style = `text-base ${baseStyle}`; // 16px
      break;
    case 5:
      style = `text-sm ${baseStyle}`; // 14px
      break;
    case 6:
      style = `text-xs ${baseStyle}`; // 12px
      break;
    case 7:
      style = `text-[10px] ${baseStyle}`; // 10px
      break;
  }

  return (
    <p className={twMerge(`${style} ${className}`)} {...props}>
      {children}
    </p>
  );
};

const Typography = {Text, Title};

export default Typography;
