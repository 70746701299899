import { useTheme } from "@/context/theme";
import { MouseEventHandler } from "react";

export const IconFlashOn = ({ ...props }: { className?: string; onClick: MouseEventHandler }) => {
  const theme = useTheme();

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M20.5281 2.34453C20.7797 2.12031 21.1023 2 21.4359 2C22.3766 2 23.0328 2.93516 22.7156 3.82109L18.9914 14.25H25.0234C25.8383 14.25 26.5 14.9117 26.5 15.7266C26.5 16.1531 26.3195 16.5523 26.0023 16.8367L11.4664 29.6609C11.2203 29.8797 10.8977 30 10.5641 30C9.62344 30 8.96719 29.0648 9.27891 28.1789L13.0086 17.75H6.91094C6.13437 17.75 5.5 17.1156 5.5 16.3391C5.5 15.9344 5.675 15.5516 5.97578 15.2836L20.5281 2.34453Z"
        fill={theme.value.dark_mode ? theme.value.brand_tertiary : theme.value.brand_secondary}
      />
    </svg>
  );
};
