import {
  IconHeartRate,
  IconRespiratoryRate,
  IconMentalStressScore,
  IconAnsBalance,
  IconMentalHealthRisk,
  IconMorningReadiness,
  IconSleepQuality,
  IconResonantBreathingScore,
} from "../Icons/Biomarker";

export const IconFromName = ({ name, className = "w-full" }: { name: string; className?: string }) => {
  // Fallback for non existance image
  let Icon = IconHeartRate;

  switch (name) {
    case "respiratory_rate":
      Icon = IconRespiratoryRate;
      break;
    case "acute_mental_stress_score":
    case "mental_stress":
      Icon = IconMentalStressScore;
      break;
    case "ans_balance":
    case "energy_balance":
      Icon = IconAnsBalance;
      break;
    case "mental_health_risk":
      Icon = IconMentalHealthRisk;
      break;
    case "morning_readiness":
      Icon = IconMorningReadiness;
      break;
    case "sleep_quality":
      Icon = IconSleepQuality;
      break;
    case "resonant_breathing_score":
      Icon = IconResonantBreathingScore;
      break;
  }

  return <Icon className={className} />;
};
